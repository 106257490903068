<template>
  <div class="container-fluid" style="padding: 20px; background-color: #6381d1;">
          <div class="container">
              <div class="row g-2">
                  <div class="col-md-10">
                      <div class="row g-2">
                          <div class="col-md-3">
                            <div class="dropdown" :class="{ show: dropdownVisibleProperty }" ref="propertyDropdown">
                                <button 
                                    class="btn btn-light dropdown-toggle w-100 py-3 d-flex justify-content-between align-items-center" 
                                    type="button" 
                                    @click="togglePropertyDropdown"
                                >
                                    <span class="text-start" style="color: rgba(0, 0, 0, 0.85);">{{ selectedPropertyType || 'ประเภทอสังหาริมทรัพย์' }}</span>
                                </button>
                                <ul class="dropdown-menu animated-dropdown" :class="{ show: dropdownVisibleProperty }">
                                    <li @click="selectPropertyType('บ้าน')" class="dropdown-item">บ้าน</li>
                                    <li @click="selectPropertyType('คอนโด')" class="dropdown-item">คอนโด</li>
                                    <li @click="selectPropertyType('ที่ดิน')" class="dropdown-item">ที่ดิน</li>
                                    <li @click="selectPropertyType('ทาวน์โฮม')" class="dropdown-item">ทาวน์โฮม</li>
                                    <li @click="selectPropertyType('อาคารพาณิชย์')" class="dropdown-item">อาคารพาณิชย์</li>
                                    <li @click="selectPropertyType('อสังหาอื่นๆ')" class="dropdown-item">อสังหาอื่น ๆ</li>
                                </ul>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="dropdown" :class="{ show: dropdownVisibleAdType }" ref="adTypeDropdown">
                                <button 
                                    class="btn btn-light dropdown-toggle w-100 py-3 d-flex justify-content-between align-items-center" 
                                    type="button" 
                                    @click="toggleAdTypeDropdown"
                                >
                                    <span class="text-start" style="color: rgba(0, 0, 0, 0.85);">{{ selectedAdType || 'ประเภทประกาศ' }}</span>
                                </button>
                                <ul class="dropdown-menu animated-dropdown" :class="{ show: dropdownVisibleAdType }">
                                    <li @click="selectAdType('ขาย')" class="dropdown-item">ขาย</li>
                                    <li @click="selectAdType('เช่า')" class="dropdown-item">เช่า</li>
                                </ul>
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="dropdown" :class="{ show: dropdownVisible }" ref="dropdown">
                              <button 
                                class="btn btn-light dropdown-toggle w-100 py-3 d-flex justify-content-between align-items-center" 
                                type="button" 
                                @click="toggleDropdown"
                                >
                                <span class="text-start" style="color: rgba(0, 0, 0, 0.85);">{{ priceRangeText }}</span>
                              </button>
                              <ul class="dropdown-menu animated-dropdown" :class="{ show: dropdownVisible }">
                                <li class="px-3 py-2">
                                  <div class="row">
                                    <div class="col-6">
                                        <label for="minPrice" class="text-center w-100 mb-1" style="font-size: 14px;">ราคาต่ำสุด</label>
                                        <div class="input-group">
                                            <input 
                                                type="text" 
                                                id="minPrice"
                                                class="form-control" 
                                                placeholder="ราคาต่ำสุด" 
                                                v-model="formattedMinprice" 
                                                @input="updateMinPrice"
                                                @focus="addFocusStyle('minPriceInput')" 
                                                @blur="removeFocusStyle('minPriceInput')"
                                                ref="minPriceInput">
                                            <span 
                                                class="input-group-text clear-icon" 
                                                @click="clearMinPrice" 
                                                ref="minPriceInput" 
                                                style="cursor: pointer;">
                                                <i class="fas fa-times mx-2"></i>
                                            </span>
                                            <span class="input-group-text text-thin">฿</span>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                      <label for="maxPrice" class="text-center w-100 mb-1" style="font-size: 14px;">ราคาสูงสุด</label>
                                      <div class="input-group">
                                          <input 
                                              type="text" 
                                              id="maxPrice"
                                              class="form-control" 
                                              placeholder="ราคาสูงสุด" 
                                              v-model="formattedMaxprice" 
                                              @input="updateMaxPrice"
                                              @focus="addFocusStyle('maxPriceInput')" 
                                              @blur="removeFocusStyle('maxPriceInput')"
                                              ref="maxPriceInput">
                                          <span 
                                              class="input-group-text clear-icon" 
                                              @click="clearMaxPrice" 
                                              ref="maxPriceInput" 
                                              style="cursor: pointer;">
                                              <i class="fas fa-times mx-2"></i>
                                          </span>
                                          <span class="input-group-text text-thin">฿</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                      </div>
                    </div>
                  <div class="col-md-2">
                      <button class="btn custom-btn border-0 w-100 py-3" @click="search">ค้นหา</button>
                  </div>
                </div>
          </div>
      </div>
</template>

<script>
export default {
  data() {
    return {
      minPrice: '',
      maxPrice: '',
      dropdownVisible: false,
      dropdownVisibleProperty: false,
      dropdownVisibleAdType: false,
      selectedPropertyType: '',
      selectedAdType: '',
    };
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  computed: {
    // ฟอร์แมตราคาให้มีคอมม่า
    formattedMinprice() {
      if (!this.minPrice) return '';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
      return Number(this.minPrice).toLocaleString();  // ใส่คอมม่าในตัวเลข
    },
    formattedMaxprice() {
      if (!this.maxPrice) return '';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
      return Number(this.maxPrice).toLocaleString();  // ใส่คอมม่าในตัวเลข
    },
    priceRangeText() {
      if (this.minPrice && this.maxPrice) {
        if (this.maxPrice < this.minPrice) {
          return `เริ่มต้น ${Number(this.minPrice).toLocaleString()} ฿`;
        } else {
          return `ตั้งแต่ ${Number(this.minPrice).toLocaleString()} - ${Number(this.maxPrice).toLocaleString()} ฿`;
        }
      } else if (this.minPrice) {
        return `เริ่มต้น ${Number(this.minPrice).toLocaleString()} ฿`;
      } else if (this.maxPrice) {
        return `ไม่เกิน ${Number(this.maxPrice).toLocaleString()} ฿`;
      } else {
        return 'ช่วงราคา';
      }
    },
  },
  methods: {
    selectCategory(category) {
      this.selectedCategory = category;
    },
    search() {
      // สร้าง query object โดยรวมค่าที่ผู้ใช้กรอกในฟิลเตอร์
      const query = {
          category: this.selectedPropertyType || '',
          adType: this.selectedAdType || '',
          minPrice: this.minPrice || '',
      };
      // เพิ่ม maxPrice ลงใน query ถ้า maxPrice ไม่ได้น้อยกว่า minPrice
      if (this.maxPrice && !(this.minPrice && parseFloat(this.maxPrice) < parseFloat(this.minPrice))) {
        query.maxPrice = this.maxPrice;
      }
      // ใช้ $router.push เพื่อเปลี่ยนเส้นทางไปยัง /propertyList พร้อมกับ query
      this.$router.push({
          path: '/propertyList',
          query,
      });
    },
    closeDropdown() {
      this.dropdownVisible = false;
    },
    closeDropdowns() {
      this.dropdownVisibleProperty = false;
      this.dropdownVisibleAdType = false;
      this.dropdownVisible = false;
    },
    togglePropertyDropdown() {
      this.dropdownVisibleProperty = !this.dropdownVisibleProperty;
      this.dropdownVisibleAdType = false;
      this.dropdownVisible = false;
    },
    toggleAdTypeDropdown() {
      this.dropdownVisibleAdType = !this.dropdownVisibleAdType;
      this.dropdownVisibleProperty = false;
      this.dropdownVisible = false;
    },
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
      this.dropdownVisibleProperty = false;
      this.dropdownVisibleAdType = false;
    },
    selectPropertyType(type) {
      this.selectedPropertyType = type;
      this.dropdownVisibleProperty = false;
    },
    selectAdType(type) {
      this.selectedAdType = type;
      this.dropdownVisibleAdType = false;
    },
    handleClickOutside(event) {
      const propertyDropdown = this.$refs.propertyDropdown;
      const adTypeDropdown = this.$refs.adTypeDropdown;
      const priceDropdown = this.$refs.dropdown;
      
      // ตรวจสอบว่าคลิกเกิดขึ้นนอก dropdown ทั้งสามตัว
      if (
        (propertyDropdown && !propertyDropdown.contains(event.target)) &&
        (adTypeDropdown && !adTypeDropdown.contains(event.target)) &&
        (priceDropdown && !priceDropdown.contains(event.target))
      ) {
        this.closeDropdowns();
        this.closeDropdown();
      }
    },
    setPriceRange(price) {
      this.minPrice = price;
      this.maxPrice = ''; // Reset max price when selecting from options
      this.dropdownVisible = false; // Close dropdown after selection
    },
    updateMinPrice(event) {
      // ลบเครื่องหมายคอมมาออกแล้วบันทึกค่าจริงในตัวแปร Price
      const value = event.target.value.replace(/,/g, '');
      this.minPrice = value ? Number(value) : '';
    },
    updateMaxPrice(event) {
      // ลบเครื่องหมายคอมมาออกแล้วบันทึกค่าจริงในตัวแปร Price
      const value = event.target.value.replace(/,/g, '');
      this.maxPrice = value ? Number(value) : '';
    },
    addFocusStyle(refName) {
      const clearIcon = this.$refs[refName];
      if (clearIcon) {
        clearIcon.classList.add('focus-style');
      }
    },
    removeFocusStyle(refName) {
      const clearIcon = this.$refs[refName];
      if (clearIcon) {
        clearIcon.classList.remove('focus-style');
      }
    },
    clearMinPrice() {
      this.minPrice = '';
    },
    clearMaxPrice() {
      this.maxPrice = '';
    },
  },
};
</script>

<style scoped>
#searchbar{
  margin: 5px;
  justify-content: center;
}
.dropdown-menu {
  width: 100%; /* ให้ dropdown ขยายเต็มความกว้างของปุ่ม */
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* เมื่อ dropdown มี class show ให้แสดง animation */
.dropdown-menu.show {
  margin-top: 0.75rem;
  opacity: 1;
  transform: scaleY(1);
}

.animated-dropdown {
  display: block; /* Override Bootstrap's default hidden state */
}

.dropdown-item {
  cursor: pointer;
}
.btn-light:hover {
    background-color: #eee3fd; /* สีม่วงอ่อน */
    color: rgba(0, 0, 0, 0.85); /* รักษาสีข้อความให้คงเดิม */
    border-color: #E6E0FF; /* หากต้องการให้เส้นขอบเปลี่ยนสีตาม */
}

input.form-control {
  width: 100%;
}

.text-thin {
  font-weight: 300; /* ใช้ค่าที่ต่ำกว่า 400 เพื่อให้ตัวอักษรบางลง */
  font-size: 1rem; /* ปรับขนาดฟอนต์ได้ตามต้องการ */
}

.input-group .form-control {
  flex: 1;
  border: 1px solid #ced4da;
  border-right: none; /* ลบเส้นขอบด้านขวาของ input เพื่อรวมกับกล่องกากบาท */
}
input::placeholder {
  color: lightgray; /* หรือคุณสามารถใช้ #f8f9fa หรือโทนสีอื่น ๆ ตามที่ต้องการ */
  opacity: 1; /* ปรับให้ placeholder ไม่จางเกินไป */
}
.form-control:focus {
  outline: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
  border-color: #6200EA; /* สีม่วงสำหรับเส้นขอบ */
}
.clear-icon {
  border: 1px solid #ced4da; /* ตั้งค่าสีขอบเริ่มต้นให้เหมือนกับ input */
  border-radius: 0.25rem; /* ปรับมุมโค้งเล็กน้อย */
  transition: border-color 0.3s ease; /* เพิ่ม transition เพื่อให้ดูสมูท */
  font-size: 1rem; /* ปรับขนาดของไอคอน */
  color: gray; /* เปลี่ยนสีของไอคอน */
  background-color: white; /* สีพื้นหลัง */
  border-left: none; /* ลบเส้นขอบด้านซ้าย */
  padding: 0.25rem; /* ปรับ padding ให้พอดี */

  display: flex; /* ปรับเพื่อให้แนวเดียวกับ input */
  align-items: center; /* จัดให้ไอคอนอยู่ตรงกลาง */
}

.focus-style {
  border-top-color: #6200EA; /* ขอบบนสีม่วง */
  border-right-color: #6200EA; /* ขอบขวาสีม่วง */
  border-bottom-color: #6200EA; /* ขอบล่างสีม่วง */
}
/* สไตล์สำหรับประเภทอสังหาริมทรัพย์และประเภทประกาศ */
.form-select {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    background-color: white;
    color: rgba(0, 0, 0, 0.85);
    appearance: none; /* ลบสไตล์ลูกศรเริ่มต้นเพื่อปรับสไตล์ใหม่ */
    -webkit-appearance: none; /* สำหรับ Safari */
}

/* สไตล์ลูกศรสำหรับ select */
.form-select::after {
    content: '▼'; /* ใช้ลูกศรเป็นสัญลักษณ์ */
    position: absolute;
    right: 15px;
    pointer-events: none;
}

/* เปลี่ยนสีและสไตล์เมื่อ hover */
.form-select:hover {
    border-color: #6200EA;
    background-color: #eee3fd; /* สีพื้นหลังเมื่อ hover */
    color: rgba(0, 0, 0, 0.85);
}

/* เมื่อ focus ให้แสดงเส้นขอบสีม่วง */
.form-select:focus {
    outline: none; /* ลบเส้นขอบ */
    border-color: #6200EA; /* สีเส้นขอบเมื่อ focus */
    box-shadow: 0 0 0 0.2rem rgba(98, 0, 234, 0.25); /* แสดงเงา */
}

/* สำหรับการแสดงรายการใน select เมื่อเปิด */
.form-select option {
    transition: background-color 0.2s ease, color 0.2s ease;
}

/* เพิ่มสไตล์เมื่อมีการเลือก option */
.form-select option:checked {
    background-color: #6200EA;
    color: white;
}
</style>