<template>
  <div  class="container bg-custom-secondary rounded shadow p-4" style="border: 1px solid #2d1f65;">
    <section class="sect">
      <div v-if="!qrCodeUrl" class="mt-5">
        <h3 class="text-white">เติมเครดิต</h3>
      </div>
      <div class="container px-md-5 my-5"> <!-- เพิ่ม px-md-5 เพื่อให้มี padding สำหรับหน้าจอขนาด medium ขึ้นไป -->
        <form v-if="!qrCodeUrl" @submit.prevent="handleSubmit">
          <div class="mb-5 row">
            <div class="col-12 col-sm-6 col-md-4" v-for="(card, index) in cards" :key="index" @click="selectCard(card.value)">
              <div class="card-container border rounded shadow p-4 my-3" :class="{ 'selected-card': selectedCard === card.value }">
                <h5>{{ card.label }}</h5>
                <h6 class="text-danger">ได้รับเครดิต {{ card.label2 }}</h6>
                <span class="text-success">{{ card.description }}</span>
              </div>
            </div>
          </div>
          <!-- ปุ่มยืนยัน -->
          <div class="mb-3 row">
            <div class="text-center">
              <button type="submit" class="btn btn-success">ยืนยัน</button>
            </div>
          </div>
          
        </form>
        <!-- QR Code -->
        <div v-if="qrCodeUrl">
          <h3 class="text-white">กรุณาสแกน QR Code เพื่อชำระเงิน</h3>
          <div class="d-flex justify-content-center">
            <div class="card col-12 col-md-8 mx-auto my-5">
              <div class="card-body">
                <div class="row">
                  <!-- โลโก้ PromptPay -->
                  <div class="col-12 text-center mt-3">
                    <img src="img/PPlogo.png" alt="PromptPay Logo" class="img-fluid" style="width: 250px;"/>
                  </div>
                  <!-- QR Code -->
                  <div class="col-12 text-center mb-3">
                    <img :src="qrCodeUrl" alt="QR Code" class="img-fluid" style="width: 300px;"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="errorForm" class="alert alert-danger">
          <ul class="d-flex flex-column align-items-center my-2">
            <li>{{ this.errorForm }}</li>
          </ul>
        </div>
        <!-- ฟอร์มสำหรับอัปโหลดสลิปหลังจากชำระเงิน -->
        <div v-if="qrCodeUrl" class="upload-section">
          <button @click="toSendingSlip" class="btn btn-success me-2">หน้าส่งสลิป</button>
          <button @click="toTopup" class="btn btn-secondary">ย้อนกลับ</button>
        </div>
      </div>
    </section>
  </div>
</template>

  
<script>
import { mapGetters } from 'vuex';


export default {
  name: 'CreditTopup',
  props: ['onSlipSent'],
  data() {
    return {
      Title: '',
      selectedCard: null,
      qrCodeUrl: null,
      file: null,    // ไฟล์สลิปที่ผู้ใช้อัปโหลด
      slipStatus: '', // สถานะการตรวจสอบสลิป
      paymentSuccess: false,
      cards: [
        { label: "100 B", label2: "100 Credit", value: "100", description: "รับเพิ่ม 0%" },
        { label: "200 B", label2: "210 Credit", value: "200", description: "รับเพิ่ม 5%" },
        { label: "500 B", label2: "550 Credit", value: "500", description: "รับเพิ่ม 10%" },
        { label: "1000 B", label2: "1150 Credit", value: "1000", description: "รับเพิ่ม 15%" },
        { label: "2000 B", label2: "2400 Credit", value: "2000", description: "รับเพิ่ม 20%" },
        { label: "5000 B", label2: "6250 Credit", value: "5000", description: "รับเพิ่ม 25%" },
      ],
      errorForm: '',
    };
  },
  computed: {
    // ฟอร์แมตราคาให้มีคอมม่า
    formattedPrice() {
      if (!this.Price) return '';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
      return Number(this.Price).toLocaleString();  // ใส่คอมม่าในตัวเลข
    },
    displayCategory() {
      return this.Category ? this.Category : 'หมวดหมู่';
    },
    ...mapGetters(['isLoggedIn', 'userId', 'userName', 'message', 'sessionExpired']),
    myCredit() {
      return this.$store.state.credit;
    }
  },
  methods: {
    selectCard(value) {
      this.selectedCard = value;
      this.errorForm = '';
    },
    checkSelected(){
      if(!this.qrCodeUrl) this.errorForm = 'กรุณาเลือกจำนวนเงินที่ต้องการ';
    },
    async generateQRCode(amount) {
      this.qrCodeUrl = `https://promptpay.io/0900000000/${amount}.png`;
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async handleSubmit() {
      if (!this.selectedCard) {
        this.errorForm = 'กรุณาเลือกจำนวนเงินที่ต้องการ';
        return; // หยุดการทำงานหากยังไม่ได้เลือกการ์ด
      }
      // เรียก API เพื่อสร้าง QR code
      try {
        // เรียก API เพื่อสร้าง QR Code จาก PromptPay.io
        const qrCodeUrl = `https://promptpay.io/0639302325/${this.selectedCard}.png`;
        this.qrCodeUrl = qrCodeUrl; // แสดง QR Code ให้ผู้ใช้สแกน
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    },
    toTopup() {
      this.qrCodeUrl = null;
    },
    toSendingSlip() {
      // เรียกฟังก์ชันที่ส่งผ่าน props เพื่อเปลี่ยนหน้า
      this.onSlipSent();
    },
  },
};
</script>
  
  <style scoped>
  .card-container {
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    background-color: white
  }
  .selected-card {
    color: white; /* เปลี่ยนสีข้อความ */
    background-color: #2d1f65; /* เปลี่ยนสีขอบ */
  }
  .selected-card h5, .selected-card h6, .selected-card span {
    color: white!important;
}
  .card-container:hover {
    transform: scale(1.03); /* ขยายเล็กน้อยเมื่อ hover */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือ */
    border-color: #2d1f65 !important;
    box-shadow: 0 8px 8px rgba(45, 31, 101, 0.747) !important;
  }
  .upload-section {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .card-container {
      margin-bottom: 20px;
    }
    .upload-section input {
      width: 100%;
    }
  }
  </style>