<template>
  <div class="embla">
    <div 
      class="embla__viewport" 
      ref="emblaViewport" 
      @mouseenter="stopAutoScroll" 
      @mouseleave="startAutoScroll"
    >
      <div class="embla__container">
        <div class="embla__slide" v-for="(slide, index) in items" :key="index">
          <picture>
            <!-- ภาพสำหรับมือถือ -->
            <source media="(max-width: 768px)" :srcset="slide.mobileImageUrl" sizes="(max-width: 768px) 700, 1296px" />
            <!-- ภาพสำหรับเดสก์ท็อป -->
            <source media="(min-width: 769px)" :srcset="slide.desktopImageUrl" sizes="(max-width: 1296px) 300, 1296px" />
            <!-- Fallback หากไม่รองรับ source -->
            <img :srcset="slide.desktopImageUrl" :alt="slide.alt" class="responsive-image" />
          </picture>
        </div>
      </div>
    </div>
  </div>
  <!-- Indicator -->
  <div class="embla__indicators">
    <button
      v-for="(slide, index) in items"
      :key="index"
      @click="scrollTo(index)"
      :class="{ 'is-selected': selectedIndex === index }"
      class="embla__indicator"
    />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue';
import EmblaCarousel from 'embla-carousel';

export default {
  setup() {

    const emblaViewport = ref(null);
    const emblaApi = ref(null);
    const selectedIndex = ref(0);
    let autoScrollInterval = null;

    const startAutoScroll = () => {
      // เริ่มเลื่อนอัตโนมัติทุก 4 วินาที
      autoScrollInterval = setInterval(() => {
        if (emblaApi.value) emblaApi.value.scrollNext();
      }, 4000);
    };

    const items = ref([
      {
        mobileImageUrl: "/img/Ad-mobile.webp", // สำหรับหน้าจอมือถือ
        desktopImageUrl: "/img/Ad-web.webp", // สำหรับหน้าจอเดสก์ท็อป
        alt: "Promotion"
      },
      {
        mobileImageUrl: '/img/Promotion-mobile.webp',
        desktopImageUrl: '/img/Promotion-web.webp',
        alt: "Advertise Section"
      },
    ]);

    const stopAutoScroll = () => {
      // หยุดเลื่อนอัตโนมัติ
      clearInterval(autoScrollInterval);
    };

    onMounted(() => {
      emblaApi.value = EmblaCarousel(emblaViewport.value, { loop: true });
      
      if (emblaApi.value) {
        emblaApi.value.on('select', () => {
          selectedIndex.value = emblaApi.value.selectedScrollSnap();
        });
        startAutoScroll(); // เริ่มการเลื่อนอัตโนมัติเมื่อคอมโพเนนต์โหลดเสร็จ
      }
    });

    onUnmounted(() => {
      stopAutoScroll(); // ล้าง interval เมื่อ component ถูกทำลาย
    });

    const scrollTo = (index) => {
      if (emblaApi.value) emblaApi.value.scrollTo(index);
    };

    return {
      items,
      emblaViewport,
      selectedIndex,
      scrollTo,
      startAutoScroll,
      stopAutoScroll,
    };
  },
};
</script>

<style scoped>
.embla {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
}

.embla__slide {
  position: relative;
  flex: 0 0 100%;
  /* padding: 10px; */
}

.embla__indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.embla__indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
  margin: 0 5px;
  border: none;
  cursor: pointer;
}

.embla__indicator.is-selected {
  background-color: #2d1f65;
}

.responsive-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  /* ขนาดภาพสำหรับมือถือ */
  .responsive-image {
    width: 100%;
    height: auto;
  }
}
</style>
