<template>
    <div class="contact-container p-5">
      <section class="sect">
        <div class="text-center mt-3">
          <h1 class="text-custom">ติดต่อเรา</h1>
        </div>
  
        <div class="container px-md-5 my-5">
          <!-- Line QR Code Section -->
          <div class="row mb-5">
            <!-- คอลัมน์ Line QR Code -->
            <div class="col-lg-6 col-md-12 text-center d-flex flex-column align-items-center mb-5 mb-lg-0">
              <h4 class="mb-3">ติดต่อผ่าน Line</h4>
              <img :src="lineQRCodeUrl" alt="Line QR Code" class="img-fluid mt-3 mx-auto" style="max-width: 100%; max-height: 500px;">
              <p class="mt-2 small-text">สแกน QR Code ด้านบนเพื่อเพิ่มเพื่อนใน Line</p>
            </div>
  
            <!-- คอลัมน์ข้อมูลติดต่อ -->
            <div class="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center">
              <h2 class="col-6 text-start mt-2 mb-5 my-md-5 contact-header">ข้อมูลติดต่อ</h2>
              <div class="contact-info d-flex flex-column w-100">
                <!-- ข้อมูลติดต่ออีเมล -->
                <div class="row mb-4 align-items-center">
                  <div class="col-2 col-md-3 text-end text-md-center">
                    <i class="fa-solid fa-envelope text-primary contact-icon"></i>
                  </div>
                  <div class="col-10 col-md-9 text-center text-md-start">
                    <h5 class="contact-title">ติดต่อผ่านอีเมล</h5>
                    <a :href="'mailto:' + contactEmail" class="text-decoration-none text-primary contact-link">
                      {{ contactEmail }}
                    </a>
                  </div>
                </div>
  
                <!-- ข้อมูลติดต่อ Line -->
                <div class="row mb-4 align-items-center">
                  <div class="col-2 col-md-3 text-end text-md-center">
                    <i class="fa-brands fa-line contact-icon" style="color: #06c755;"></i>
                  </div>
                  <div class="col-10 col-md-9 text-center text-md-start">
                    <h5 class="contact-title">ติดต่อผ่าน Line</h5>
                    <a href="https://line.me/R/ti/p/@709mhewi" class="text-decoration-none contact-link" target="_blank" style="color: #06c755;">
                      @709mhewi
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ContactPage',
    data() {
      return {
        lineQRCodeUrl: 'img/line-add.jpg', // URL ของรูปภาพ QR Code
        contactEmail: 'kaizub.com@hotmail.com', // อีเมลของเจ้าหน้าที่
      };
    },
  };
  </script>
  
  <style scoped>
  .contact-container {
    min-height: 100vh; /* เพิ่มความสูงขั้นต่ำเป็น 100% ของ viewport height */
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  h5 {
    font-weight: bold;
  }
  
  a {
    font-size: 1.2rem;
  }
  
  /* ทำให้ไอคอนและข้อความยืดหยุ่นตามขนาดหน้าจอ */
  .contact-icon {
    font-size: 50px;
  }
  
  /* ปรับขนาดตัวอักษรให้เล็กลงในหน้าจอขนาดเล็ก */
  .contact-title {
    font-size: 1.2rem;
  }
  
  .contact-header {
    font-size: 2rem;
  }
  
  /* Media Queries สำหรับหน้าจอขนาดเล็ก */
  @media (max-width: 768px) {
    .contact-icon {
      font-size: 30px; /* ปรับขนาดไอคอนให้เล็กลง */
    }
  
    .contact-title {
      font-size: 1rem; /* ลดขนาดของ h5 */
    }
  
    .contact-header {
      font-size: 1.5rem; /* ลดขนาด header */
    }
  
    .contact-link {
      font-size: 1rem; /* ลดขนาดของลิงก์ */
    }
  
    .small-text {
      font-size: 0.9rem; /* ลดขนาดตัวอักษรของข้อความอื่น */
    }
  }
  </style>
  