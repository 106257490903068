<template>
  <div class="container bg-white border rounded shadow p-4">
    <section class="sect">
      <div class="mt-5">
        <h3>แก้ไขข้อมูลอสังหาริมทรัพย์</h3>
      </div>

      <div class="container px-md-5 my-5"> 
        <form @submit.prevent="handleEdit">
          <!-- ประเภทการขาย -->
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">ประเภทการขาย <span class="text-danger">*</span></label>
            <div class="col-sm-9 text-start">
              <div class="form-check form-check-inline">
                <input v-model="PropertyType" class="form-check-input" type="radio" id="sale" value="ขาย" required>
                <label class="form-check-label" for="sale">ขาย</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="PropertyType" class="form-check-input" type="radio" id="rent" value="เช่า">
                <label class="form-check-label" for="rent">ให้เช่า</label>
              </div>
            </div>
          </div>

          <!-- หมวดหมู่ -->
          <div class="mb-3 row">
            <label for="category" class="col-sm-3 col-form-label text-start">หมวดหมู่ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-2">
              <div class="dropdown" v-if="!Category">
                <!-- แสดง dropdown เฉพาะเมื่อ Category ยังไม่มีค่าเท่านั้น -->
                <a class="btn btn-secondary form-control dropdown-toggle bg-light text-dark text-start" role="button" data-bs-toggle="dropdown" aria-expanded="true">{{ displayCategory }}</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" @click="selectCategory('ที่ดิน')">ที่ดิน</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('บ้าน')">บ้าน</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('คอนโด')">คอนโด</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('ทาวน์โฮม')">ทาวน์โฮม</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('อาคารพาณิชย์')">อาคารพาณิชย์</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('อื่น ๆ')">อื่น ๆ</a></li>
                </ul>
              </div>
              <div v-else>
                <!-- แสดงเป็นข้อความแบบคงที่เมื่อ Category มีค่าแล้ว -->
                <input v-model="Category" type="text" class="form-control" readonly disabled />
              </div>
            </div>
          </div>

          <!-- ส่วนของ Location Selector -->
          <!-- ภูมิภาค -->
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">ภูมิภาค <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-9">
              <div class="row">
                <!-- Dropdown ภูมิภาค -->
                <div class="col-md-4 col-sm-12">
                  <div class="input-group">
                    <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownRegion" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="d-inline-block text-truncate" style="max-width: 100px;">{{ selectedRegionName || 'ภูมิภาค' }}</span>
                    </button>
                    <ul class="dropdown-menu limited-dropdown" aria-labelledby="dropdownRegion">
                      <li v-for="region in regions" :key="region.id">
                        <a class="dropdown-item" href="#" @click.prevent="selectRegion(region)">{{ region.name }}</a>
                      </li>
                    </ul>
                    <span class="input-group-text col-5">ภาค</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ที่อยู่ -->
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">ที่อยู่ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-9">
              <div class="row">
                <!-- Dropdown จังหวัด -->
                <div class="col-md-4 col-sm-12 mb-2 mb-md-0">
                  <div class="input-group">
                    <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownProvince" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedRegion">
                      <span class="d-inline-block text-truncate" style="max-width: 100px;">{{ selectedProvinceName || 'จังหวัด' }}</span>
                    </button>
                    <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownProvince">
                      <li v-for="province in filteredProvinces" :key="province.id">
                        <a class="dropdown-item" href="#" @click.prevent="selectProvince(province)">{{ province.name_th }}</a>
                      </li>
                    </ul>
                    <span class="input-group-text col-5">จังหวัด</span>
                  </div>
                </div>

                <!-- Dropdown อำเภอ -->
                <div class="col-md-4 col-sm-12 mb-2 mb-md-0">
                  <div class="input-group">
                    <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownAmphur" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedProvince">
                      <span class="d-inline-block text-truncate">{{ selectedAmphurName || 'อำเภอ' }}</span>
                    </button>
                    <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownAmphur">
                      <li v-for="amphur in filteredAmphurs" :key="amphur.id">
                        <a class="dropdown-item" href="#" @click.prevent="selectAmphur(amphur)">{{ amphur.name_th }}</a>
                      </li>
                    </ul>
                    <span class="input-group-text col-5">อำเภอ</span>
                  </div>
                </div>

                <!-- Dropdown ตำบล -->
                <div class="col-md-4 col-sm-12">
                  <div class="input-group">
                    <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownTambon" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedAmphur">
                      <span class="d-inline-block text-truncate">{{ selectedTambonName || 'ตำบล' }}</span>
                    </button>
                    <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownTambon">
                      <li v-for="tambon in filteredTambons" :key="tambon.id">
                        <a class="dropdown-item" href="#" @click.prevent="selectTambon(tambon)">{{ tambon.name_th }}</a>
                      </li>
                    </ul>
                    <span class="input-group-text col-5">ตำบล</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- หัวข้ออสังหาริมทรัพย์ -->
          <div class="mb-3 row">
            <label for="productName" class="col-sm-3 col-form-label text-start">หัวข้ออสังหาริมทรัพย์ <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <input v-model="Title" type="text" class="form-control" id="productName" placeholder="หัวข้อประกาศ เช่น ขายบ้านทำเลดีย่านการค้า" required>
            </div>
          </div>
          <!-- ฟิลด์รายละเอียดบ้าน (houseDetails) -->
          <div v-if="Category !== 'ที่ดิน'" class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">รายละเอียดบ้าน</label>
            <div class="col-sm-9">
              <div class="row mb-3">
                <div class="col-md-4">
                  <input v-model="houseDetails.bedrooms" type="number" class="form-control" placeholder="จำนวนห้องนอน">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.bathrooms" type="number" class="form-control" placeholder="จำนวนห้องน้ำ">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.floors" type="number" class="form-control" placeholder="จำนวนชั้น">
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <input v-model="houseDetails.landScale" type="number" class="form-control" placeholder="ขนาดที่ดิน (ตร.ว)">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.houseScale" type="number" class="form-control" placeholder="พื้นที่ใช้สอย (ตร.ม)">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.carsSlot" type="number" class="form-control" placeholder="จำนวนพื้นที่จอดรถ">
                </div>
              </div>
            </div>
          </div>
          <!-- สิ่งอำนวยความสะดวกในบ้าน -->
          <div v-if="Category !== 'ที่ดิน'" class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">สิ่งอำนวยความสะดวกในบ้าน</label>
            <div class="col-sm-9">
              <div class="row">
                <!-- Column 1 -->
                <div class="col-md-6">
                  <div class="col-md-10 mx-auto">
                    <div class="form-check my-4">
                      <input v-model="facilities.furniture" class="form-check-input" type="checkbox" id="furniture">
                      <label class="form-check-label" for="furniture">เฟอร์นิเจอร์</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.airConditioner" class="form-check-input" type="checkbox" id="airConditioner">
                      <label class="form-check-label" for="airConditioner">เครื่องปรับอากาศ</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.digitalLock" class="form-check-input" type="checkbox" id="digitalLock">
                      <label class="form-check-label" for="digitalLock">ประตูดิจิตอล</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.tv" class="form-check-input" type="checkbox" id="tv">
                      <label class="form-check-label" for="tv">โทรทัศน์</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.refrigerator" class="form-check-input" type="checkbox" id="refrigerator">
                      <label class="form-check-label" for="refrigerator">ตู้เย็น</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.microwave" class="form-check-input" type="checkbox" id="microwave">
                      <label class="form-check-label" for="microwave">ไมโครเวฟ</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.swimmingpool" class="form-check-input" type="checkbox" id="swimmingpool">
                      <label class="form-check-label" for="swimmingpool">สระว่ายน้ำ</label>
                    </div>
                  </div>
                </div>

                <!-- Column 2 -->
                <div class="col-md-6">
                  <div class="col-md-10 mx-auto">
                    <div class="form-check my-4">
                      <input v-model="facilities.landlinePhone" class="form-check-input" type="checkbox" id="landlinePhone">
                      <label class="form-check-label" for="landlinePhone">โทรศัพท์บ้าน</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.hotWaterMachine" class="form-check-input" type="checkbox" id="hotWaterMachine">
                      <label class="form-check-label" for="hotWaterMachine">เครื่องทำน้ำอุ่น</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.bathtub" class="form-check-input" type="checkbox" id="bathtub">
                      <label class="form-check-label" for="bathtub">อ่างอาบน้ำ</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.kitchen" class="form-check-input" type="checkbox" id="kitchen">
                      <label class="form-check-label" for="kitchen">เตาปรุงอาหาร</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.cookerhood" class="form-check-input" type="checkbox" id="cookerhood">
                      <label class="form-check-label" for="cookerhood">เครื่องดูดควัน</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.fitness" class="form-check-input" type="checkbox" id="fitness">
                      <label class="form-check-label" for="fitness">ฟิตเนส</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.fan" class="form-check-input" type="checkbox" id="fan">
                      <label class="form-check-label" for="fan">พัดลม</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- รายละเอียด -->
          <div class="mb-3 row">
            <label for="detail" class="col-sm-3 col-form-label text-start">รายละเอียด <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <textarea v-model="Detail" id="detail" name="detail" cols="40" rows="10" class="form-control" required></textarea>
            </div>
          </div>

          <!-- เนื้อที่ -->
          <div class="mb-3 row">
            <label for="scale" class="col-sm-3 col-form-label text-start">เนื้อที่ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-9">
              <div class="row">
                <div class="col-12 col-md">
                  <div class="input-group mb-2 mb-md-0">
                    <input v-model="Scale.ScaleRai" @input="validateInteger('ScaleRai', $event)" type="number" min="0" step="1" class="form-control text-center" required>
                    <span class="input-group-text">ไร่</span>
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="input-group mb-2 mb-md-0">
                    <input v-model="Scale.ScaleNgan" @input="validateInteger('ScaleNgan', $event)" type="number" min="0" step="1" class="form-control text-center" required>
                    <span class="input-group-text">งาน</span>
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="input-group mb-2 mb-md-0">
                    <input v-model="Scale.ScaleWah" @input="validateInteger('ScaleWah', $event)" type="number" min="0" step="1" class="form-control text-center" required>
                    <span class="input-group-text">ตารางวา</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ราคา -->
          <div class="mb-3 row">
            <label for="price" class="col-sm-3 col-form-label text-start">ราคา <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-4">
              <div class="input-group">
                <input v-model="formattedPrice" @input="updatePrice" type="text" class="form-control" id="price" required>
                <span class="input-group-text">บาท</span>
              </div>
            </div>
          </div>

          <!-- ทำเล -->
          <div class="mb-3 row">
            <label for="position" class="col-sm-3 col-form-label text-start">ทำเล</label>
            <div class="col-sm-9">
              <input v-model="Position" type="text" class="form-control" id="position" placeholder="เช่น ใกล้ตลาด, ใกล้โรงเรียน">
            </div>
          </div>

          <!-- การติดต่อ -->
          <div class="mb-3 row">
            <label for="Telnum" class="col-sm-3 col-form-label text-start">การติดต่อ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-4">
              <input v-model="Telnum" type="tel" class="form-control" id="Telnum" disabled required>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="line" class="col-sm-3 col-form-label text-start">Line ID</label>
            <div class="col-sm-9 col-md-4">
              <input v-model="Line" type="text" class="form-control" id="line" disabled>
            </div>
          </div>

          <!-- Google Maps Link -->
          <div class="mb-3 row">
            <label for="latitude" class="col-sm-3 col-form-label text-start">แผนที่</label>
            <div class="col-sm-4 col-md-4">
              <input v-model="Latitude" type="text" class="form-control" id="latitude" placeholder="ละติจูด">
            </div>
            <div class="col-sm-4 col-md-4">
              <input v-model="Longitude" type="text" class="form-control" id="longitude" placeholder="ลองจิจูด">
            </div>
          </div>

          <!-- เลือกรูปภาพ -->
          <div class="mb-5 row">
            <label for="imageInput" class="col-sm-3 col-form-label text-start">เลือกรูปภาพ</label>
            <div class="col-sm-9 col-md-4">
              <input type="file" ref="imageInput" class="form-control" id="imageInput" @change="handleImageChange" multiple/>
            </div>
          </div>

          <!-- แสดงตัวอย่างรูปภาพ -->
          <div class="row mb-5">
            <div class="col-sm-9 offset-sm-3 bg-white border rounded">
              <div class="row">
                <div class="col-3 mb-3" v-for="(image, index) in imagePreviews" :key="index">
                  <div class="card position-relative mt-2">
                    <img :src="getImageSrc(image)" class="card-img" alt="image preview" />
                    <!-- ปุ่มลบรูปภาพ -->
                    <button type="button" class="btn btn-danger btn-sm position-absolute top-0 end-0 m-2" @click="removeImage(index)">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ปุ่มบันทึกการแก้ไข -->
          <div class="mb-3 row">
            <div class="text-center">
              <button type="submit" class="btn btn-primary me-2">บันทึกการแก้ไข</button>
              <button type="button" class="btn btn-secondary" @click="showCancelModal = true">ยกเลิก</button>
            </div>
          </div>
        </form>
        <!-- Modal สำหรับยืนยันการบันทึก -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="showModal" class="modal-backdrop fade show" @click="closeModal"></div>
          </transition>
          <transition name="modal">
            <div v-if="showModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-header">
                    <div class="mx-auto">
                        <h5 class="modal-title p-2">ยืนยันการแก้ไขประกาศ</h5>
                        <button type="button" class="btn-close" @click="closeModal" style="position: absolute; right: 20px; top: 20px;"></button>
                    </div>
                  </div>
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <h6>คุณต้องการบันทึกการแก้ไขข้อมูลหรือไม่?</h6>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal">ยกเลิก</button>
                    <button type="button" class="btn btn-primary" @click="confirmSubmit">ยืนยัน</button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>

        <!-- Modal สำหรับแจ้งเตือนการบันทึก -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="showNotification" class="modal-backdrop fade show"></div>
          </transition>
          <transition name="modal">
            <div v-if="showNotification" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-header">
                    <div class="mx-auto">
                      <div class="modal-body p-2" v-if="isSuccess">
                        <div class="col-8 mx-auto text-center">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 131 131"
                            width="100%"
                            height="100%" 
                          >
                            <circle
                              class="circle"
                              fill="none"
                              stroke="#198754"
                              stroke-width="6"
                              stroke-miterlimit="10"
                              cx="65.1"
                              cy="65.1"
                              r="62.1"
                            />
                            <polyline
                              class="check"
                              fill="none"
                              stroke="#198754"
                              stroke-width="6"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              points="100.2,40.2 51.5,88.8 29.8,67.5 "
                            />
                          </svg>  
                        </div>
                        <h3 class="text-success mx-auto mt-3">แก้ไขสำเร็จ</h3> 
                      </div>

                      <div class="modal-body p-2" v-if="!isSuccess">
                        <div class="col-8 mx-auto text-center">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 131 131"
                            width="100%"
                            height="100%"
                          >
                            <circle
                              class="circle"
                              fill="none"
                              stroke="#db3646"
                              stroke-width="6"
                              stroke-miterlimit="10"
                              cx="65.1"
                              cy="65.1"
                              r="62.1"
                            />
                            <line
                              class="line1"
                              fill="none"
                              stroke="#db3646"
                              stroke-width="6"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              x1="34.4"
                              y1="37.9"
                              x2="95.8"
                              y2="92.3"
                            />
                            <line
                              class="line2"
                              fill="none"
                              stroke="#db3646"
                              stroke-width="6"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              x1="95.8"
                              y1="38"
                              x2="34.4"
                              y2="92.2"
                            />
                          </svg>
                        </div>
                        <h3 class="text-danger text-center mt-3">แก้ไขล้มเหลว</h3> 
                      </div>
                    </div>
                  </div>
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <h6 v-if="isSuccess">ระบบจะกลับไปหน้าจอรายการประกาศในไม่ช้า...</h6>
                    <div v-else class="text-center">
                          <h6>กรุณาตรวจสอบข้อมูลหรือลองอีกครั้ง</h6>
                          <h6>หากพบปัญหากรุณาติดต่อแอดมิน</h6>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>

        <!-- แสดง Modal กำลังบันทึก -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="isSubmitting" class="modal-backdrop fade show"></div>
          </transition>
          <transition name="modal">
            <div v-if="isSubmitting" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <h5 class="mt-3">กำลังบันทึกข้อมูล...</h5>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
        <teleport to="body">
          <!-- Backdrop -->
          <transition name="backdrop">
            <div v-if="showCancelModal" class="modal-backdrop fade show" @click="showCancelModal = false"></div>
          </transition>
          <!-- Modal -->
          <transition name="modal">
            <div v-if="showCancelModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <!-- Modal Header -->
                  <div class="modal-header">
                    <h4 class="modal-title p-2 mx-auto">ยืนยันการออกจากหน้านี้</h4>
                    <button type="button" class="btn-close" @click="showCancelModal = false" style="position: absolute; right: 20px; top: 20px;"></button>
                  </div>
                  <!-- Modal Body -->
                  <div class="modal-body">
                    <h6 class="text-center">คุณแน่ใจว่าต้องการออกจากหน้านี้หรือไม่?</h6>
                  </div>
                  <!-- Modal Footer -->
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="cancelCancel">ยกเลิก</button>
                    <button type="button" class="btn btn-primary" @click="confirmCancel">ยืนยัน</button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
        <!-- Modal แจ้งเตือนรูปภาพ -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="showSizeLimitModal" class="modal-backdrop fade show"></div>
          </transition>
          <transition name="modal">
            <div v-if="showSizeLimitModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-header">
                    <div class="mx-auto">
                      <h5 class="modal-title p-2 text-danger">ข้อผิดพลาดในการอัพโหลดรูปภาพ</h5>
                      <button type="button" class="btn-close" @click="showSizeLimitModal = false" style="position: absolute; right: 20px; top: 20px;"></button>
                    </div>
                  </div>
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <div class="text-center">
                      <div class="p-4">
                        <h6 class="text-danger">ไฟล์ "{{ oversizedFileName }}"</h6>
                        <h6 class="text-danger">{{ fileErrorMessage }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
      </div>
    </section>
  </div>
</template>

<script>
import axiosDefault from 'axios'; // สำหรับการโหลดไฟล์ JSON
import axios from '@/axios.js';

export default {
  name: 'EditProperty',
  props: ['propertyId'],
  data() {
    return {
      Title: '',
      PropertyType: null,
      Category: null,
      Detail: '',
      Scale: {
        ScaleRai: null,
        ScaleNgan: null,
        ScaleWah: null,
      },
      houseDetails: {
        bedrooms: null,
        bathrooms: null,
        floors: null,
        landScale: null,
        houseScale: null,
        carsSlot: null
      },
      facilities: {
        furniture: false,
        airConditioner: false,
        digitalLock: false,
        tv: false,
        refrigerator: false,
        landlinePhone: false,
        hotWaterMachine: false,
        bathtub: false,
        kitchen: false,
        cookerhood: false,
        fitness: false,
        fan: false,
        swimmingpool: false,
        microwave: false
      },
      Price: '',
      Telnum: '',
      Line: '',
      Latitude: '',
      Longitude: '',
      isOtherSelected: false,
      showCancelModal: false,
      confirmCallback: null,
      pendingNavigation: null, //ตัวแปรที่เก็บฟังก์ชัน next
      otherCategory: '',
      // ข้อมูลของ Location Selector
      selectedRegion: null,
      selectedProvince: null,
      selectedAmphur: null,
      selectedTambon: null,
      regions: [],
      provinces: [],
      amphurs: [],
      tambons: [],
      filteredProvinces: [],
      filteredAmphurs: [],
      filteredTambons: [],
      existingImages: [],    // เก็บ URL ของรูปภาพที่มีอยู่แล้ว
      newImages: [],         // เก็บไฟล์รูปภาพใหม่ที่ผู้ใช้เพิ่มเข้ามา
      imagesToDelete: [],    // เก็บ URL ของรูปภาพที่ผู้ใช้ต้องการลบ
      imagePreviews: [],     // เก็บข้อมูลสำหรับแสดงตัวอย่างรูปภาพ
      locationData: {},      // เพิ่มตัวแปร locationData ใน data
      // เพิ่มตัวแปรที่ใช้สำหรับ Modal
      showModal: false,
      isSubmitting: false,
      showNotification: false,
      isSuccess: false,
      showSizeLimitModal: false, // ควบคุมการแสดงผลของ modal ขนาดไฟล์เกินกำหนด
      oversizedFileName: '',     // เก็บชื่อไฟล์ที่มีปัญหา
      fileErrorMessage: '',      // เก็บข้อความแสดงข้อผิดพลาด
    };
  },
  // เพิ่ม beforeRouteLeave เพื่อป้องกันการนำทางออกจากหน้า
  beforeRouteLeave(to, from, next) {
    if (this.showCancelModal) {
      // ป้องกันการซ้อนของโมดาล
      next(false); // ยกเลิกการนำทางถ้าโมดาลยังแสดงอยู่
      return;
    }
    this.pendingNavigation = next;
    this.showUnsavedChangesModal();
  },
  mounted() {
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  },
  computed: {
    displayCategory() {
      return this.Category ? this.Category : 'หมวดหมู่';
    },
    formattedPrice() {
      return Number(this.Price).toLocaleString();
    },
    // Computed properties สำหรับ Location Selector
    selectedRegionName() {
      return this.selectedRegion ? this.selectedRegion.name : '';
    },
    selectedProvinceName() {
      return this.selectedProvince ? this.selectedProvince.name_th : '';
    },
    selectedAmphurName() {
      return this.selectedAmphur ? this.selectedAmphur.name_th : '';
    },
    selectedTambonName() {
      return this.selectedTambon ? this.selectedTambon.name_th : '';
    },
  },
  async created() {
    await this.loadPropertyData(); // เรียกฟังก์ชันโหลดข้อมูลเมื่อคอมโพเนนต์ถูกสร้างขึ้น
    await this.loadLocationData(); // โหลดข้อมูลสำหรับ Location Selector
  },
  methods: {
    async loadPropertyData() {
      // รีเซ็ตค่า
      this.Title = '';
      this.PropertyType = null;
      this.Category = null;
      this.Detail = '';
      this.Scale = {
        ScaleRai: null,
        ScaleNgan: null,
        ScaleWah: null,
      };
      this.houseDetails = {
        bedrooms: null,
        bathrooms: null,
        floors: null,
        landScale: null,
        houseScale: null,
        carsSlot: null
      };
      this.facilities = {
        furniture: false,
        airConditioner: false,
        digitalLock: false,
        tv: false,
        refrigerator: false,
        landlinePhone: false,
        hotWaterMachine: false,
        bathtub: false,
        kitchen: false,
        cookerhood: false,
        fitness: false,
        fan: false,
        swimmingpool: false,
        microwave: false
      };
      this.Price = '';
      this.Position = '';
      this.Telnum = '';
      this.Line = '';
      this.Latitude = '';
      this.Longitude = '';
      this.selectedRegion = null;
      this.selectedProvince = null;
      this.selectedAmphur = null;
      this.selectedTambon = null;
      this.filteredProvinces = [];
      this.filteredAmphurs = [];
      this.filteredTambons = [];
      this.isOtherSelected = false;
      this.otherCategory = '';
      this.existingImages = [];
      this.newImages = [];
      this.imagesToDelete = [];
      this.imagePreviews = [];

      const propertyId = this.propertyId;
      try {
        const response = await axios.get(`/api/property/detail/${propertyId}`);
        const property = response.data;

        // นำข้อมูลจาก property มาใส่ในฟอร์ม
        this.Title = property.Title;
        this.PropertyType = property.PropertyType;
        this.Category = property.Category;
        this.Detail = property.Detail;
        this.Scale = property.Scale;
        this.houseDetails = property.houseDetails;
        this.Price = property.Price;
        this.Position = property.Position;
        this.Telnum = property.Telnum;
        this.Line = property.Line;
        this.Latitude = property.Latitude;
        this.Longitude = property.Longitude;

        // ตั้งค่าข้อมูล Location
        this.locationData = {
          region: property.Location.Region,
          province: property.Location.Province,
          amphur: property.Location.Amphur,
          tambon: property.Location.Tambon,
        };
        // โหลดข้อมูล facilities โดยตรวจสอบว่ามีข้อมูลอะไรบ้าง
        if (property.facilities) {
          const facilitiesFromDb = property.facilities;

          // ตั้งค่า facilities ให้เป็น true เฉพาะฟิลด์ที่มีในฐานข้อมูล
          this.facilities = {
            furniture: facilitiesFromDb.includes('furniture'),
            airConditioner: facilitiesFromDb.includes('airConditioner'),
            digitalLock: facilitiesFromDb.includes('digitalLock'),
            tv: facilitiesFromDb.includes('tv'),
            refrigerator: facilitiesFromDb.includes('refrigerator'),
            landlinePhone: facilitiesFromDb.includes('landlinePhone'),
            hotWaterMachine: facilitiesFromDb.includes('hotWaterMachine'),
            bathtub: facilitiesFromDb.includes('bathtub'),
            kitchen: facilitiesFromDb.includes('kitchen'),
            cookerhood: facilitiesFromDb.includes('cookerhood'),
            fitness: facilitiesFromDb.includes('fitness'),
            fan: facilitiesFromDb.includes('fan'),
            swimmingpool: facilitiesFromDb.includes('swimmingpool'),
            microwave: facilitiesFromDb.includes('microwave')
          };
        }
        // ตั้งค่ารูปภาพที่มีอยู่
        this.existingImages = property.images || [];
        this.imagePreviews = [...this.existingImages];

      } catch (error) {
        console.error('Error fetching property details:', error);
      }
    },
    async loadLocationData() {
      // โหลดข้อมูลสำหรับ Location Selector
      try {
        await Promise.all([
          this.loadRegions(),
          this.loadProvinces(),
          this.loadAmphurs(),
          this.loadTambons(),
        ]);
        this.setInitialValues();
      } catch (error) {
        console.error('Error loading location data:', error);
      }
    },
    // ฟังก์ชันของ Location Selector
    selectRegion(region) {
      this.selectedRegion = region;
      this.filteredProvinces = this.provinces.filter(province => province.geography_id === region.id);
      this.selectedProvince = null;
      this.selectedAmphur = null;
      this.selectedTambon = null;
      this.filteredAmphurs = [];
      this.filteredTambons = [];
    },
    selectProvince(province) {
      this.selectedProvince = province;
      this.filteredAmphurs = this.amphurs.filter(amphur => amphur.province_id === province.id);
      this.selectedAmphur = null;
      this.selectedTambon = null;
      this.filteredTambons = [];
    },
    selectAmphur(amphur) {
      this.selectedAmphur = amphur;
      this.filteredTambons = this.tambons.filter(tambon => tambon.amphure_id === amphur.id);
      this.selectedTambon = null;
    },
    selectTambon(tambon) {
      this.selectedTambon = tambon;
    },
    async loadRegions() {
      try {
        const response = await axiosDefault.get('/thai_geographies.json');
        this.regions = response.data;
      } catch (error) {
        console.error('Error loading regions:', error);
      }
    },
    async loadProvinces() {
      try {
        const response = await axiosDefault.get('/thai_provinces.json');
        this.provinces = response.data;
      } catch (error) {
        console.error('Error loading provinces:', error);
      }
    },
    async loadAmphurs() {
      try {
        const response = await axiosDefault.get('/thai_amphures.json');
        this.amphurs = response.data;
      } catch (error) {
        console.error('Error loading amphurs:', error);
      }
    },
    async loadTambons() {
      try {
        const response = await axiosDefault.get('/thai_tambons.json');
        this.tambons = response.data;
      } catch (error) {
        console.error('Error loading tambons:', error);
      }
    },
    setInitialValues() {
      if (this.locationData.region) {
        const region = this.regions.find(r => r.name === this.locationData.region);
        if (region) {
          this.selectRegion(region);
        }
      }
      if (this.locationData.province) {
        const province = this.provinces.find(p => p.name_th === this.locationData.province);
        if (province) {
          this.selectProvince(province);
        }
      }
      if (this.locationData.amphur) {
        const amphur = this.amphurs.find(a => a.name_th === this.locationData.amphur);
        if (amphur) {
          this.selectAmphur(amphur);
        }
      }
      if (this.locationData.tambon) {
        const tambon = this.tambons.find(t => t.name_th === this.locationData.tambon);
        if (tambon) {
          this.selectTambon(tambon);
        }
      }
    },
    handleImageChange(event) {
      const files = event.target.files;

      for (let file of files) {
        // ตรวจสอบว่าประเภทของไฟล์เป็นรูปภาพหรือไม่
        if (!file.type.startsWith('image/')) {
          this.oversizedFileName = file.name;
          this.fileErrorMessage = 'กรุณาอัพโหลดรูปภาพเท่านั้น';
          this.showSizeLimitModal = true; // แสดง modal เมื่อประเภทไฟล์ไม่ถูกต้อง
          this.$refs.imageInput.value = ''; // เคลียร์ค่าของ input file
          continue;
        }

        // ตรวจสอบขนาดของไฟล์ หากเกิน 2MB ให้แสดง modal แจ้งเตือน
        if (file.size > 1 * 1024 * 1024) {
          this.oversizedFileName = file.name;
          this.fileErrorMessage = 'ไฟล์มีขนาดใหญ่เกิน 1MB กรุณาเลือกไฟล์ที่มีขนาดเล็กกว่า 1MB';
          this.showSizeLimitModal = true; // แสดง modal เมื่อไฟล์มีขนาดเกินกำหนด
          this.$refs.imageInput.value = ''; // เคลียร์ค่าของ input file
          continue;
        }

        this.newImages.push(file); // เก็บไฟล์ใน `newImages`

        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreviews.push({
            file: file, // เก็บไฟล์เพื่อใช้งานภายใน
            url: e.target.result, // Data URL สำหรับแสดงตัวอย่าง
          });
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage(index) {
      const image = this.imagePreviews[index];

      // ตรวจสอบว่ารูปภาพนี้เป็นรูปภาพที่มีอยู่หรือไม่
      const existingImageIndex = this.existingImages.findIndex(img => img.url === image.url);

      if (existingImageIndex !== -1) {
        // ถ้าเป็นรูปภาพที่มีอยู่ ให้เพิ่มไปยัง imagesToDelete
        this.imagesToDelete.push(this.existingImages[existingImageIndex]);

        // ลบออกจาก existingImages
        this.existingImages.splice(existingImageIndex, 1);
      } else {
        // ถ้าเป็นรูปภาพใหม่ ให้หาตำแหน่งใน newImages และลบออก
        const newImageIndex = this.newImages.findIndex(file => file === image.file);
        if (newImageIndex !== -1) {
          this.newImages.splice(newImageIndex, 1);
        }

        // เคลียร์ค่าใน input file
        this.$refs.imageInput.value = '';
      }

      // ลบออกจาก imagePreviews
      this.imagePreviews.splice(index, 1);
    },
    getImageSrc(image) {
      return image.url;
    },
    showUnsavedChangesModal(callback) {
      this.showCancelModal = true;
      this.confirmCallback = callback;
    },
    beforeUnloadListener(event) {
      event.preventDefault();
      event.returnValue = '';
    },
    confirmCancel() {
      this.showCancelModal = false;
      if (this.pendingNavigation) {
        const next = this.pendingNavigation;
        this.pendingNavigation = null;
        // นำทางไปยังหน้า Dashboard ที่แสดง ListProperty
        next('/dashboardPage'); // หรือเส้นทางที่คุณต้องการ
      } else {
        // ถ้าไม่มี pendingNavigation (เช่น เมื่อกดปุ่ม "ยกเลิก" ในฟอร์ม)
        this.$emit('cancel-edit');
      }
    },
    cancelCancel() {
      this.showCancelModal = false;
      if (this.pendingNavigation) {
        const next = this.pendingNavigation;
        this.pendingNavigation = null;
        next(false); // ยกเลิกการนำทาง
      }
    },
    // เพิ่ม method สำหรับ Modal
    handleEdit() {
      this.showModal = true; // แสดง modal ถ้าฟอร์มถูกต้อง
    },
    closeModal() {
      this.showModal = false;
    },
    confirmSubmit() {
      this.showModal = false;
      this.isSubmitting = true;
      this.handleSubmit(); // เรียกการบันทึกข้อมูล
    },
    closeNotification() {
      this.showNotification = false;
    },
    async handleSubmit() {
      const propertyId = this.propertyId;
      try {
        // รวบรวมข้อมูล Location ก่อนส่ง
        const locationData = {
          region: this.selectedRegion ? this.selectedRegion.name : null,
          province: this.selectedProvince ? this.selectedProvince.name_th : null,
          amphur: this.selectedAmphur ? this.selectedAmphur.name_th : null,
          tambon: this.selectedTambon ? this.selectedTambon.name_th : null,
        };

        // สร้าง FormData เพื่อส่งข้อมูลและไฟล์
        const formData = new FormData();

        formData.append('Title', this.Title);
        formData.append('PropertyType', this.PropertyType);
        formData.append('Category', this.isOtherSelected ? this.otherCategory : this.Category);
        formData.append('Detail', this.Detail);
        formData.append('Scale', JSON.stringify(this.Scale));
        formData.append('Price', this.Price);
        formData.append('Position', this.Position);
        formData.append('Telnum', this.Telnum);
        formData.append('Line', this.Line);
        formData.append('Longitude', this.Longitude);
        formData.append('Latitude', this.Latitude);
        formData.append('Location', JSON.stringify(locationData));
        formData.append('houseDetails', JSON.stringify(this.houseDetails));

        // ส่ง facilities (เก็บเฉพาะ key ที่มีค่าเป็น true)
        const selectedFacilities = Object.keys(this.facilities).filter(key => this.facilities[key] === true);
        formData.append('facilities', JSON.stringify(selectedFacilities));

        // ส่งรูปภาพใหม่
        this.newImages.forEach((file) => {
          formData.append('newImages', file);
        });

        // ส่งรายการรูปภาพที่ต้องการลบ
        formData.append('imagesToDelete', JSON.stringify(this.imagesToDelete));

        // ส่งรายการรูปภาพที่คงเหลืออยู่
        formData.append('existingImages', JSON.stringify(this.existingImages));

        // ส่งข้อมูลไปยังเซิร์ฟเวอร์
        const response = await axios.put(`/api/property/update/${propertyId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 200) {
          this.isSubmitting = false;
          this.isSuccess = true;
          this.showNotification = true;
          // หลังจาก 3 วินาทีเปลี่ยนหน้าไปยัง dashboard
          setTimeout(() => {
            this.closeNotification(); // ปิด modal สำเร็จ
            this.$emit('cancel-edit');
          }, 3000);
        } else {
          this.isSubmitting = false;
          this.isSuccess = false;
          this.showNotification = true;
        }
      } catch (error) {
        this.isSubmitting = false;
        this.isSuccess = false;
        this.showNotification = true;
        console.error('Error updating property:', error);
      } 
    },
    selectCategory(category) {
      this.Category = category;
      if (category === 'อื่น ๆ') {
        this.isOtherSelected = true;
      } else {
        this.isOtherSelected = false;
      }
    },
    updatePrice(event) {
      const value = event.target.value.replace(/,/g, '');
      this.Price = value ? Number(value) : '';
    },
    validateInteger(field, event) {
      let value = event.target.value;
      if (value.includes('.')) {
        value = Math.floor(value);
      }
      if (value.length > 1 && value.startsWith('0')) {
        value = parseInt(value, 10);
      }
      this.Scale[field] = value;
      event.target.value = value;
    },
  },
  watch: {
    propertyId: {
      handler() {
        this.loadPropertyData(); // โหลดข้อมูลใหม่เมื่อ propertyId เปลี่ยนแปลง
      },
      immediate: true,
    },
  },
};
</script>

<style scoped>
  .card-container {
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  }
  .selected-card {
    border-color: #2d1f65 !important;
    box-shadow: 0 4px 8px rgba(45, 31, 101, 0.5) !important;
  }
  .card-container:hover {
    transform: scale(1.03); /* ขยายเล็กน้อยเมื่อ hover */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือ */
  }
  .card img {
    max-height: 150px;
    object-fit: cover;
  }
  .no-padding-left .btn {
    padding-left: 0 !important;
  }

.modal {
    font-family: 'Prompt', sans-serif;
}
.modal-content {
  border: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
}

/* สำหรับ Backdrop */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s ease;
}
.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

/* สำหรับ Modal */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* ปรับขนาด Modal สำหรับหน้าจอคอมพิวเตอร์ */
.custom-modal-dialog {
  max-width: 600px; 
}
.set-position {
  margin-right: 1rem;
}

/* เพิ่มไอคอนและเอฟเฟกต์ให้ modal */
.bi-check-circle-fill {
  font-size: 2rem;
}
.bi-x-circle-fill {
  font-size: 2rem;
}

.checkmark-svg {
  transform: scale(0); /* เริ่มจากขนาดเล็ก */
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.modal-enter-active .checkmark-svg {
  transform: scale(1); /* ขยายขึ้นสู่ขนาดปกติ */
  opacity: 1;
}

.circle {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}
.check {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: draw-check 0.5s forwards 0.5s;
}
@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-check {
  to {
    stroke-dashoffset: 0;
  }
}

:deep(.circle) {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}

:deep(.line1),
:deep(.line2) {
  stroke-dasharray: 85;
  stroke-dashoffset: 85;
  animation: draw-lines 0.5s forwards 0.5s;
}

@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-lines {
  to {
    stroke-dashoffset: 0;
  }
}

.custom-modal-content svg {
  max-width: 120px;
  max-height: 120px;
}

/* ปรับขนาด Modal สำหรับมือถือ */
@media (max-width: 768px) { /* ขยายขอบเขตจาก 576px เป็น 768px */
  
}
.limited-dropdown {
  max-height: 200px;
  overflow-y: auto;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
