<template>
  <div class="container bg-white rounded shadow p-4" style="border: 1px solid #2d1f65;">
    <section class="sect">
      <div class="mt-5">
        <h3>แก้ไขข้อมูลผู้ใช้</h3>
      </div>

      <div class="container px-md-5 my-5">
        <form @submit.prevent="handleEditUser">
          <!-- ชื่อผู้ใช้ -->
          <div class="mb-3 row">
            <label for="username" class="col-sm-3 col-form-label text-start">ชื่อผู้ใช้</label>
            <div class="col-sm-9">
              <input v-model="user.userName" type="text" class="form-control" id="username" placeholder="ชื่อผู้ใช้" disabled>
            </div>
          </div>

          <!-- ชื่อที่จะแสดง -->
          <div class="mb-3 row">
            <label for="name" class="col-sm-3 col-form-label text-start">ชื่อที่จะแสดง</label>
            <div class="col-sm-9">
              <input v-model="user.name" type="text" class="form-control" id="name" placeholder="ชื่อที่จะแสดงให้ผู้อื่นเห็น">
            </div>

            <div class="col-sm-9 ms-auto">
              <span class="text-danger" style="font-size: small;">
                **ห้ามใช้ชื่อ Admin หรือแอบอ้างชื่อผู้อื่น พบเห็นแบนทันที**
              </span>
            </div>
          </div>

          <!-- อีเมล -->
          <div class="mb-3 row">
            <label for="email" class="col-sm-3 col-form-label text-start">อีเมล</label>
            <div class="col-sm-9">
              <input v-model="user.email" type="email" class="form-control" id="email" placeholder="อีเมล">
            </div>
          </div>

          <!-- เบอร์โทรศัพท์ -->
          <div class="mb-3 row">
            <label for="Telnum" class="col-sm-3 col-form-label text-start">เบอร์โทรศัพท์</label>
            <div class="col-sm-9">
              <input v-model="user.Telnum" type="tel" class="form-control" id="Telnum" placeholder="เบอร์โทรศัพท์">
            </div>
          </div>

          <!-- Line -->
          <div class="mb-3 row">
            <label for="Line" class="col-sm-3 col-form-label text-start">Line ID</label>
            <div class="col-sm-9">
              <input v-model="user.Line" type="text" class="form-control" id="Line" placeholder="line id">
            </div>
          </div>

          <!-- ปุ่มบันทึกการแก้ไข -->
          <div class="mb-3 row">
            <div class="text-center">
              <button type="submit" class="btn btn-primary me-2">บันทึกการแก้ไข</button>
              <button type="button" class="btn btn-secondary" @click="showUnsavedChangesModal(() => { this.$router.push({ path: '/dashboardPage', query: { component: 'ListProperty' } }); })">ยกเลิก</button>
            </div>
          </div>
        </form>

        <!-- Modal สำหรับแจ้งเตือนการบันทึก -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="showNotification" class="modal-backdrop fade show"></div>
          </transition>
          <transition name="modal">
            <div v-if="showNotification" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-header">
                    <div class="mx-auto">
                      <div class="modal-body p-2" v-if="isSuccess">
                        <div class="col-8 mx-auto text-center">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 131 131"
                            width="100%"
                            height="100%" 
                          >
                            <circle
                              class="circle"
                              fill="none"
                              stroke="#198754"
                              stroke-width="6"
                              stroke-miterlimit="10"
                              cx="65.1"
                              cy="65.1"
                              r="62.1"
                            />
                            <polyline
                              class="check"
                              fill="none"
                              stroke="#198754"
                              stroke-width="6"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              points="100.2,40.2 51.5,88.8 29.8,67.5 "
                            />
                          </svg>  
                        </div>
                        <h3 class="text-success mx-auto mt-3">แก้ไขสำเร็จ</h3> 
                      </div>

                      <div class="modal-body p-2" v-if="!isSuccess">
                        <div class="col-8 mx-auto text-center">
                          <svg
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 131 131"
                            width="100%"
                            height="100%"
                          >
                            <circle
                              class="circle"
                              fill="none"
                              stroke="#db3646"
                              stroke-width="6"
                              stroke-miterlimit="10"
                              cx="65.1"
                              cy="65.1"
                              r="62.1"
                            />
                            <line
                              class="line1"
                              fill="none"
                              stroke="#db3646"
                              stroke-width="6"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              x1="34.4"
                              y1="37.9"
                              x2="95.8"
                              y2="92.3"
                            />
                            <line
                              class="line2"
                              fill="none"
                              stroke="#db3646"
                              stroke-width="6"
                              stroke-linecap="round"
                              stroke-miterlimit="10"
                              x1="95.8"
                              y1="38"
                              x2="34.4"
                              y2="92.2"
                            />
                          </svg>
                        </div>
                        <h3 class="text-danger text-center mt-3">แก้ไขล้มเหลว</h3> 
                      </div>
                      <button type="button" class="btn-close" v-if="!isSuccess" @click="closeNotification" style="position: absolute; right: 20px; top: 20px;"></button>
                    </div>
                  </div>
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <h6 v-if="isSuccess">ระบบจะกลับไปหน้าจอรายการประกาศในไม่ช้า...</h6>
                    <div v-else class="text-center">
                          <h6>กรุณาตรวจสอบข้อมูลหรือลองอีกครั้ง</h6>
                          <h6>หากพบปัญหากรุณาติดต่อแอดมิน</h6>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>

        <!-- แสดง Modal กำลังบันทึก -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="isSubmitting" class="modal-backdrop fade show"></div>
          </transition>
          <transition name="modal">
            <div v-if="isSubmitting" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <h5 class="mt-3">กำลังบันทึกข้อมูล...</h5>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
        <!-- Modal ยืนยันการออกจากหน้า -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="showCancelModal" class="modal-backdrop fade show" @click="showCancelModal = false"></div>
          </transition>
          <transition name="modal">
            <div v-if="showCancelModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <!-- Modal Header -->
                  <div class="modal-header">
                    <h4 class="modal-title p-2 mx-auto">ยืนยันการออกจากหน้านี้</h4>
                    <button type="button" class="btn-close" @click="showCancelModal = false" style="position: absolute; right: 20px; top: 20px;"></button>
                  </div>
                  <!-- Modal Body -->
                  <div class="modal-body">
                    <h6 class="text-center">คุณแน่ใจว่าต้องการออกจากหน้านี้หรือไม่?</h6>
                  </div>
                  <!-- Modal Footer -->
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="cancelCancel">ยกเลิก</button>
                    <button type="button" class="btn btn-primary" @click="confirmCancel">ยืนยัน</button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
      </div>
    </section>
  </div>
</template>

<script>
import axios from '@/axios.js';
import { mapGetters } from 'vuex';

export default {
  name: 'UserInfoPage',
  data() {
    return {
      user: {
        userName: '',
        name: '',
        email: '',
        Telnum: '',
        Line: ''
      },
      showCancelModal: false,
      isSubmitting: false,
      showNotification: false,
      isSuccess: false,
      pendingNavigation: null, // ตัวแปรสำหรับเก็บข้อมูลการนำทางที่กำลังจะเกิดขึ้น
      confirmCallback: null, // ตัวแปรเก็บ callback ที่จะเรียกหลังจากยืนยัน
    };
  },
  async created() {
    await this.loadUserData();
  },
  mounted() {
    // เพิ่ม listener เพื่อป้องกันการปิดหรือรีเฟรชหน้าโดยไม่ได้รับการยืนยัน
    window.addEventListener('beforeunload', this.beforeUnloadListener);
  },
  beforeUnmount() {
    // ลบ listener เมื่อคอมโพเนนต์ถูก unmount
    window.removeEventListener('beforeunload', this.beforeUnloadListener);
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'userId', 'userName', 'message', 'sessionExpired']),
  },
  methods: {
    async loadUserData() {
      try {
        const response = await axios.get(`/auth/user-edit/${this.userId}`);
        console.log(response.data)
        if (response.data) {
          // ตั้งค่าข้อมูลที่ได้รับจากฐานข้อมูลให้กับตัวแปร user
          this.user = {
            userName: response.data.userName,
            name: response.data.name,
            email: response.data.email,
            Telnum: response.data.Telnum,
            Line: response.data.Line
          };
        }
      } catch (error) {
        console.error('Error loading user data:', error);
      }
    },
    async handleEditUser() {
      this.isSubmitting = true;
      try {
        const response = await axios.put(`/auth/update/${this.userId}`, this.user);
        if (response.status === 200) {
          this.isSubmitting = false;
          this.isSuccess = true;
          this.showNotification = true;

          // เรียกใช้ loadUserData เพื่อโหลดข้อมูลใหม่หลังจากการอัปเดตสำเร็จ
          
          this.$store.commit('setName', response.data.user.name);
          this.$store.commit('setEmail', response.data.user.email);
          this.$store.commit('setTel', response.data.user.Telnum);
          this.$store.commit('setLine', response.data.user.Line);
          await this.loadUserData();

          // เพิ่มการหน่วงเวลา 3 วินาทีก่อนเปลี่ยนไปหน้า ListProperty
          setTimeout(() => {
            this.closeNotification(); // ปิด modal สำเร็จ
            this.$router.push({ path: '/dashboardPage', query: { component: 'ListProperty' } });
          }, 3000);
        }
      } catch (error) {
        console.error('Error updating user data:', error);
        this.isSubmitting = false;
        this.showNotification = true;
        this.isSuccess = false;
      }
    },
    closeNotification() {
      this.showNotification = false;
      if (this.isSuccess) {
        this.$router.push({ name: 'UserProfile' }); // เปลี่ยนเส้นทางไปยังหน้าข้อมูลส่วนตัวหลังจากบันทึกสำเร็จ
      }
    },
    confirmCancel() {
      this.showCancelModal = false;
      if (this.pendingNavigation) {
        // หากผู้ใช้ยืนยัน ให้ดำเนินการนำทางต่อ
        const next = this.pendingNavigation;
        this.pendingNavigation = null;
        next(); // อนุญาตการนำทาง
      } else if (this.confirmCallback) {
        // หากมี callback ให้เรียกใช้งาน callback
        this.confirmCallback();
        this.confirmCallback = null;
      }
    },
    cancelCancel() {
      this.showCancelModal = false;
      if (this.pendingNavigation) {
        // ยกเลิกการนำทาง
        const next = this.pendingNavigation;
        this.pendingNavigation = null;
        next(false); // ปฏิเสธการนำทาง
      }
    },
    showUnsavedChangesModal(callback) {
      this.showCancelModal = true;
      this.confirmCallback = callback;
    },
    beforeUnloadListener(event) {
      // ตรวจสอบว่ามีการเปลี่ยนแปลงข้อมูลหรือไม่และยังไม่ได้รับการยืนยัน
      event.preventDefault();
      event.returnValue = ''; // แสดงข้อความแจ้งเตือนให้ผู้ใช้ยืนยันก่อนปิดหรือรีเฟรช
    },
  },
  // เพิ่ม beforeRouteLeave เพื่อป้องกันการนำทางออกจากหน้า
  beforeRouteLeave(to, from, next) {
    if (this.showCancelModal) {
      // ป้องกันการซ้อนของโมดาล
      next(false); // ยกเลิกการนำทางถ้าโมดาลยังแสดงอยู่
      return;
    }
    // เก็บการนำทางที่กำลังจะเกิดขึ้นไว้ใน pendingNavigation
    this.pendingNavigation = next;

    // แสดง Modal ยืนยันการออกจากหน้า
    this.showUnsavedChangesModal();
  },
};
</script>



<style scoped>
  .container {
    max-width: 700px;
    margin: auto;
  }
  .modal-content {
    border: none;
    box-shadow: none;
  }
  .modal {
    font-family: 'Prompt', sans-serif;
}
.modal-content {
  border: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
}

/* สำหรับ Backdrop */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s ease;
}
.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

/* สำหรับ Modal */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* ปรับขนาด Modal สำหรับหน้าจอคอมพิวเตอร์ */
.custom-modal-dialog {
  max-width: 600px; 
}
.set-position {
  margin-right: 1rem;
}

/* เพิ่มไอคอนและเอฟเฟกต์ให้ modal */
.bi-check-circle-fill {
  font-size: 2rem;
}
.bi-x-circle-fill {
  font-size: 2rem;
}

.checkmark-svg {
  transform: scale(0); /* เริ่มจากขนาดเล็ก */
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.modal-enter-active .checkmark-svg {
  transform: scale(1); /* ขยายขึ้นสู่ขนาดปกติ */
  opacity: 1;
}

.circle {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}
.check {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: draw-check 0.5s forwards 0.5s;
}
@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-check {
  to {
    stroke-dashoffset: 0;
  }
}

:deep(.circle) {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}

:deep(.line1),
:deep(.line2) {
  stroke-dasharray: 85;
  stroke-dashoffset: 85;
  animation: draw-lines 0.5s forwards 0.5s;
}

@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-lines {
  to {
    stroke-dashoffset: 0;
  }
}

.custom-modal-content svg {
  max-width: 120px;
  max-height: 120px;
}

/* ปรับขนาด Modal สำหรับมือถือ */
@media (max-width: 768px) { /* ขยายขอบเขตจาก 576px เป็น 768px */
  
}
.limited-dropdown {
  max-height: 200px;
  overflow-y: auto;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
