<template>
  <div class="container">
    <section>
      <form id="formRegister" @submit.prevent="form_submit" method="post">
        
        <div class="mt-5 col-12 col-md-6 mb-5 mx-auto">
          <div class="card">

            
            <div class="card-body mt-3">
              <img src="../img/logo.png" alt="logo" width="30%" height="30%">
              <div class="mt-3">
                <h4>สมัครสมาชิก</h4>
              </div>
            
              <div class="container px-md-5 my-5">
                <div class="row text-start">
                  <label for="username" class="col-sm-3 col-form-label">
                    ชื่อผู้ใช้
                    <span class="text-danger font-weight-bold">*</span>
                  </label>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <input v-model.trim="formData.username" name="username" type="text" class="form-control" id="username" placeholder="ชื่อผู้ใช้" required>
                  </div>
                </div>

                <div class="row text-start">
                  <label for="email" class="col-sm-3 col-form-label">
                    อีเมล์
                    <span class="text-danger font-weight-bold">*</span>
                  </label>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <input v-model.trim="formData.email" name="email" type="email" class="form-control" id="email" placeholder="อีเมล์" required>
                  </div>
                </div>

                <div class="row text-start">
                  <label for="pw" class="col-sm-3 col-form-label">
                    รหัสผ่าน
                    <span class="text-danger font-weight-bold">*</span>
                  </label>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <input v-model.trim="formData.password" name="password" type="password" class="form-control" id="password" minlength="6" placeholder="รหัสผ่าน" required>
                  </div>
                </div>

                <div class="row text-start">
                  <label for="pwconfirm" class="col-sm-3 col-form-label">
                    ยืนยันรหัสผ่าน
                    <span class="text-danger font-weight-bold">*</span>
                  </label>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <input v-model.trim="formData.pwconfirm" name="pwconfirm" type="password" class="form-control" id="pwconfirm" minlength="6" placeholder="ยืนยันรหัสผ่าน" required>
                  </div>
                </div>

                <div v-if="error" class="alert alert-danger">
                  {{ error }}
                </div>

                <div class="alert alert-primary">
                  <i class="bi bi-info-circle"></i> รหัสผ่านใช้ตัวอักษร 6 ตัวขึ้นไป
                </div>

                <div class="mt-5 mb-3 row">
                  <div class="text-center">
                      <button type="submit" class="btn btn-primary col-12">สมัครสมาชิก</button>
                  </div>
                </div>
                <div class="mb-3 row">
                  <div class="col d-flex justify-content-between">
                    <a href="/forgotpassword/" class="text-decoration-none">ลืมรหัสผ่าน?</a>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      formData: {
        username: '',
        email: '',
        password: '',
        pwconfirm: ''
      },
      error: null
    };
  },
  methods: {
    ...mapActions(['register']),
    async form_submit() {
      this.error = null;
      if (this.formData.password !== this.formData.pwconfirm) {
        this.error = 'รหัสผ่านไม่ตรงกัน';
        this.formData.password = '';
        this.formData.pwconfirm = '';
        this.$nextTick(() => {
          this.$refs.password.focus();
        });
        return;
      }

      try {
        // const res = await axios.post('http://localhost:3000/api/user/update-credit', {
        //   userId,
        //   selectedCard
        // })
        await this.register({
          username: this.formData.username,
          email: this.formData.email,
          password: this.formData.password
        });
        alert('ข้อมูลถูกบันทึกแล้ว');
        this.$router.push('/');
      } catch (error) {
        this.error = error.message || 'มีข้อผิดพลาดเกิดขึ้น';
      }
    }
  }
};
</script>
  
  <style scoped>

  input[type=password]:invalid {
    border-color: salmon;
  }

  input[type=password]:valid {
    border-color: green;
  }
  </style>