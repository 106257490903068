<template>
  <div  class="container bg-white border rounded shadow p-4">
    <section class="sect">
      <h3 class="mt-5">แจ้งการโอน</h3>
      <div class="container px-md-5 my-5"> <!-- เพิ่ม px-md-5 เพื่อให้มี padding สำหรับหน้าจอขนาด medium ขึ้นไป -->
        <!-- ฟอร์มสำหรับอัปโหลดสลิปหลังจากชำระเงิน -->
        <div class="upload-section">
          <form @submit.prevent="handleSubmit">
            <input type="file" @change="handleFileUpload" class="form-control mb-3" />
            <div v-if="files">
              <img :src="imageUrl" alt="slip" class="img-fluid mt-3" />
            </div>
            <button @click="submitSlip" class="btn btn-success mt-3">ส่งสลิป</button>
          </form>
        </div>
        <!-- แสดงผลการตรวจสอบสลิป -->
      </div>
    </section>

    <!-- Modal สำหรับแจ้งเตือนการบันทึกสำเร็จหรือไม่สำเร็จ -->
    <teleport to="body">
      <transition name="backdrop">
        <div v-if="showNotification" class="modal-backdrop fade show"></div>
      </transition>
      <transition name="modal">
        <div v-if="showNotification" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
          <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
            <div class="modal-content custom-modal-content">
              <div class="modal-header">
                <div class="mx-auto">
                  <div class="modal-body p-2" v-if="isSuccess">
                    <div class="col-8 mx-auto">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 131 131"
                        width="100%"
                        height="100%" 
                      >
                        <circle
                          class="circle"
                          fill="none"
                          stroke="#198754"
                          stroke-width="6"
                          stroke-miterlimit="10"
                          cx="65.1"
                          cy="65.1"
                          r="62.1"
                        />
                        <polyline
                          class="check"
                          fill="none"
                          stroke="#198754"
                          stroke-width="6"
                          stroke-linecap="round"
                          stroke-miterlimit="10"
                          points="100.2,40.2 51.5,88.8 29.8,67.5 "
                        />
                      </svg>  
                    </div>
                    <h3 class="text-success mx-auto mt-3">ตรวจสอบสำเร็จ</h3> 
                  </div>
                  
                  <div class="modal-body p-2" v-if="!isSuccess">
                    <div class="col-8 mx-auto">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 131 131"
                        width="100%"
                        height="100%"
                      >
                        <circle
                          class="circle"
                          fill="none"
                          stroke="#db3646"
                          stroke-width="6"
                          stroke-miterlimit="10"
                          cx="65.1"
                          cy="65.1"
                          r="62.1"
                        />
                        <line
                          class="line1"
                          fill="none"
                          stroke="#db3646"
                          stroke-width="6"
                          stroke-linecap="round"
                          stroke-miterlimit="10"
                          x1="34.4"
                          y1="37.9"
                          x2="95.8"
                          y2="92.3"
                        />
                        <line
                          class="line2"
                          fill="none"
                          stroke="#db3646"
                          stroke-width="6"
                          stroke-linecap="round"
                          stroke-miterlimit="10"
                          x1="95.8"
                          y1="38"
                          x2="34.4"
                          y2="92.2"
                        />
                      </svg>
                    </div>
                    <h3 class="text-danger text-center mt-3">ตรวจสอบล้มเหลว</h3> 
                  </div>
                  <button type="button" class="btn-close" v-if="!isSuccess" @click="closeNotification" style="position: absolute; right: 20px; top: 20px;"></button>
                </div>
              </div>
              <div class="modal-body d-flex align-items-center justify-content-center">
                <div class="text-center">
                  <div v-if="isSuccess">
                    <h5 class="text-success">ตรวจสอบการชำระเงินเรียบร้อย</h5>
                    <h5 class="text-success">ระบบได้เพิ่มเครดิตให้กับบัญชีของท่านแล้ว</h5>
                  </div>
                  <div v-else>
                    <h5 class="text-danger">โปรดตรวจสอบสลิป</h5>
                    <h5 class="text-danger">หากพบปัญหากรุณาติดต่อแอดมิน</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
    <teleport to="body">
      <transition name="backdrop">
        <div v-if="isSubmitting" class="modal-backdrop fade show"></div>
      </transition>
      <transition name="modal">
        <div v-if="isSubmitting" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
          <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
            <div class="modal-content custom-modal-content">
              <div class="modal-body d-flex align-items-center justify-content-center">
                <div class="text-center">
                  <!-- แสดงอนิเมชันโหลดจาก Bootstrap -->
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <!-- แสดงข้อความกำลังบันทึก -->
                  <div class="mt-3">
                    <h5>กำลังตรวจสอบข้อมูล...</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </teleport>
  </div>
</template>

  
<script>
import axios from '@/axios.js';
import { mapGetters } from 'vuex';


export default {
  name: 'SendingSlip',
  components: {
  },
  data() {
    return {
      files: null,    // ไฟล์สลิปที่ผู้ใช้อัปโหลด
      paymentSuccess: false,
      imageUrl: '',
      showNotification: false,
      isSuccess: false,
      isSubmitting: false,
    };
  },
  computed: {
    // ฟอร์แมตราคาให้มีคอมม่า
    formattedPrice() {
      if (!this.Price) return '';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
      return Number(this.Price).toLocaleString();  // ใส่คอมม่าในตัวเลข
    },
    ...mapGetters(['isLoggedIn', 'userId', 'userName', 'message', 'sessionExpired']),
    myCredit() {
      return this.$store.state.credit;
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;  // ปิด modal
    },
    closeNotification() {
      this.showNotification = false; // ปิด modal แจ้งเตือน
    },
    handleFileUpload(event) {
      const selectedFile = event.target.files[0];  // เก็บไฟล์ที่ผู้ใช้อัปโหลด

      // ตรวจสอบว่าไฟล์มีค่าและเป็นชนิดไฟล์จริง
      if (selectedFile && selectedFile instanceof Blob) {
        this.files = selectedFile;  // จัดเก็บไฟล์
        this.imageUrl = URL.createObjectURL(this.files);  // สร้าง URL สำหรับแสดงรูป
      }
    },
    async checkPaymentStatus() {
      // เรียก API เพื่อเช็คสถานะการชำระเงิน
      try {
        const response = await axios.get('/api/payment/status');
        if (response.data.status === 'success') {
          this.paymentSuccess = true;
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
      }
    },
    async handleSubmit(event) {
      event.preventDefault();

      this.isSubmitting = true;

      const formData = new FormData();
      formData.append("files", this.files);

      try{
        const response = await axios.post('/api/payment/status', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
        const result = response.data;  // รับผลลัพธ์จากการอัปโหลด
        if (result.status === 'success') {
          this.$store.commit('setCredit', result.credit);
          this.isSubmitting = false;
          this.isSuccess = true;
          this.showNotification = true;
          setTimeout(() => {
            window.location.reload();
            this.showNotification = false;
          }, 3000);
        } else {
          this.files = null;
          this.isSuccess = false;
          this.isSubmitting = false;
          this.showNotification = true;
        }
      } catch {
        //console.error('Error uploading slip:', error);
        this.files = null;
        this.isSuccess = false;
        this.showNotification = true;
        this.isSubmitting = false;
      }
    }
  },
};
</script>
  
  <style scoped>
  .card-container {
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  }
  .selected-card {
    color: white;
    background-color: #2d1f65;
  }
  .card-container:hover {
    transform: scale(1.03);
    cursor: pointer;
  }
  .upload-section {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .card-container {
      margin-bottom: 20px;
    }
    .upload-section input {
      width: 100%;
    }
  }

  .modal {
    font-family: 'Prompt', sans-serif;
}
.modal-content {
  border: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
}

/* สำหรับ Backdrop */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s ease;
}
.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

/* สำหรับ Modal */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* ปรับขนาด Modal สำหรับหน้าจอคอมพิวเตอร์ */
.custom-modal-dialog {
  max-width: 600px; 
}
.set-position {
  margin-right: 1rem;
}

/* เพิ่มไอคอนและเอฟเฟกต์ให้ modal */
.bi-check-circle-fill {
  font-size: 2rem;
}
.bi-x-circle-fill {
  font-size: 2rem;
}
.checkmark-svg {
  transform: scale(0); /* เริ่มจากขนาดเล็ก */
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.modal-enter-active .checkmark-svg {
  transform: scale(1); /* ขยายขึ้นสู่ขนาดปกติ */
  opacity: 1;
}

.circle {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}
.check {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: draw-check 0.5s forwards 0.5s;
}
@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-check {
  to {
    stroke-dashoffset: 0;
  }
}

:deep(.circle) {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}

:deep(.line1),
:deep(.line2) {
  stroke-dasharray: 85;
  stroke-dashoffset: 85;
  animation: draw-lines 0.5s forwards 0.5s;
}

@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-lines {
  to {
    stroke-dashoffset: 0;
  }
}

.custom-modal-content svg {
  max-width: 120px;
  max-height: 120px;
}
  </style>