import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'animate.css';
import WOW from 'wowjs';
//import axiosInstance  from '@/axios';
//bootsrap//
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
//import 'bootstrap-icons/font/bootstrap-icons.css'
//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/dist/js/bootstrap.bundle.min.js'
//import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css';  // Import CSS vue easy lightbox
import './assets/css/style.css';
import '@fortawesome/fontawesome-free/css/all.css';

//const BASE_URL = 'http://localhost:3000';

new WOW.WOW().init();

// axios.defaults.withCredentials = true; // เพื่อให้ cookie ถูกส่งไปทุก request
// // Axios interceptor to handle JWT token expiration
// axios.interceptors.response.use(
//   response => response,
//   async error => {
//     const originalRequest = error.config;

//     if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       try {
//         await axios.post(`${BASE_URL}/auth/refresh-token`);
//         return axios(originalRequest);
//       } catch (refreshError) {
//         // ถ้า refresh token ล้มเหลว อัปเดต Vuex Store และนำผู้ใช้ไปที่หน้า login
//         store.commit('setLoggedIn', false);
//         store.commit('setUserId', null);
//         store.commit('setUserName', '');
//         router.push('/login');
//         return Promise.reject(refreshError);
//       }
//     }

//     return Promise.reject(error);
//   }
// );
// axios.interceptors.response.use(
//     response => response,
//     async error => {
//       const originalRequest = error.config;
  
//       if (error.response.status === 401 && !originalRequest._retry && store.getters.isLoggedIn) {
//         originalRequest._retry = true; // ป้องกันการ retry ซ้ำๆ
  
//         try {
//           // เรียก API เพื่อ refresh access token
//           const { data } = await axios.post(`${BASE_URL}/auth/refresh-token`);
//           store.commit('setAccessToken', data.accessToken);  // เก็บ Access Token ใหม่ใน Vuex Store
//           originalRequest.headers['Authorization'] = `Bearer ${data.accessToken}`;
  
//           return axios(originalRequest);  // ส่ง request เดิมอีกครั้งด้วย Access Token ใหม่
//         } catch (refreshError) {
//           store.commit('setLoggedIn', false);
//           router.push('/login');  // หากการ refresh token ล้มเหลว ส่งผู้ใช้ไปที่หน้า login
//         }
//       }
  
//       return Promise.reject(error);
//     }
//   );
//createApp(App).mount('#app')
const app = createApp(App);
//app.use(BootstrapVue);
//app.use(IconsPlugin);
app.use(router);
app.use(store);
app.mount('#app');
