<template>
  <div id="app">
    <div v-if="errorMessage && errorMessage === 'เซสชันของคุณหมดอายุแล้ว โปรดเข้าสู่ระบบใหม่'" class="error-alert">
      {{ errorMessage }}
    </div>
      <SpinnerComponent :isLoading="isLoading" />
      <NavbarTestUnlogin/>
      <div class="bg-light">
        <router-view/>
      </div>
      <BackToTop/>
      <FooterSection/>
  </div>
</template>

<script>

//import AlertTest from './components/AlertTest.vue'
//import NavbarTest from './components/NavbarTest.vue'
import NavbarTestUnlogin from './components/NavbarTestUnlogin.vue'
//import BodyContain from './components/BodyContain.vue'
import FooterSection from './components/FooterSection.vue'
import BackToTop from './components/BackToTop.vue';
import SpinnerComponent from './components/SpinnerComponent.vue';

import { mapState, mapActions } from 'vuex'
import { WOW } from 'wowjs';
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import 'animate.css';

new WOW().init();

export default {
  name: 'App',
  setup() {
    const store = useStore();
    const router = useRouter();
    const errorMessage = computed(() => store.getters.error);
    const sessionExpired = computed(() => store.getters.sessionExpired);


    // เรียก checkAuth เมื่อแอปเริ่มต้น
    onMounted(() => {
      store.commit('setSessionExpired', false); // รีเซ็ต sessionExpired
      store.dispatch('checkAuth');
    });

    // ดูการเปลี่ยนแปลงของ sessionExpired
    watch(sessionExpired, (newValue) => {
      if (newValue) {
        // รีเซ็ต sessionExpired ทันทีเพื่อป้องกันการนำทางซ้ำ
        store.commit('setSessionExpired', false);
        // แสดงข้อความแจ้งเตือน
        store.commit('setError', 'เซสชันของคุณหมดอายุแล้ว โปรดเข้าสู่ระบบใหม่');
        // นำทางไปที่ /login หากไม่ได้อยู่ที่นั่นอยู่แล้ว
        if (router.currentRoute.value.path !== '/') {
          router.push('/');
        }
      }
    });

    // ดูการเปลี่ยนแปลงของ errorMessage และล้างหลังจากแสดง
    watch(errorMessage, (newValue) => {
      if (newValue) {
        setTimeout(() => {
          store.commit('setError', null);
        }, 5000);
      }
    });

    return {
      errorMessage,
    };
  },
  components: {
    //AlertTest,
    NavbarTestUnlogin,
    //NavbarTest,
    //BodyContain,
    FooterSection,
    BackToTop,
    SpinnerComponent
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.isLoggedIn,
      isLoading: state => state.isLoading,
      userId: state => state.userId,
      message: state => state.message
    })
  },
  methods: {
    ...mapActions([
      'register',
      'login',
      'logout',
      'checkAuth',
      //'getProtectedData'
    ]),
    /*async handleRegister() {
      await this.register({ username: this.username, password: this.password })
    },
    async handleLogin() {
      await this.login({ username: this.username, password: this.password })
    }*/
  },
  created() {
    //this.checkAuth()

    this.$router.beforeEach((to, from, next) => {
      this.isLoading = true;
      next();
    });

    this.$router.afterEach(() => {
      setTimeout(() => {
        this.isLoading = false;
      }, 250); // เพิ่ม delay เล็กน้อยเพื่อให้เห็นการทำงานของ spinner
    });
  },
  
  mounted() {
    new WOW().init();
  }
}
</script>

<style>


#app {
  font-family: 'Prompt', Avenir, Helvetica, Arial, sans-serif;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  height: 100%;
  padding-top: 61px;
}
.error-alert {
  /* สไตล์ของคุณ */
  color: red;
  background-color: #ffe6e6;
  padding: 10px;
  margin: 10px;
}
</style>
