<template>
  <!-- ภูมิภาค -->
  <div class="mb-3 row">
    <label class="col-sm-3 col-form-label text-start">ภูมิภาค <span class="text-danger">*</span></label>
    <div class="col-sm-9 col-md-9">
      <div class="row">
        <!-- Dropdown ภูมิภาค -->
        <div class="col-md-4 col-sm-12">
          <div class="input-group">
            <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownRegion" data-bs-toggle="dropdown" aria-expanded="false">
              <span class="d-inline-block text-truncate" style="max-width: 100px;">{{ selectedRegionName || 'ภูมิภาค' }}</span>
            </button>
            <ul class="dropdown-menu limited-dropdown" aria-labelledby="dropdownRegion">
              <li v-for="region in regions" :key="region.id">
                <a class="dropdown-item" href="#" @click.prevent="selectRegion(region)">{{ region.name }}</a>
              </li>
            </ul>
            <span class="input-group-text col-5">ภาค</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ที่อยู่ -->
  <div class="mb-3 row">
    <label class="col-sm-3 col-form-label text-start">ที่อยู่ <span class="text-danger">*</span></label>
    <div class="col-sm-9 col-md-9">
      <div class="row">
        <!-- Dropdown จังหวัด -->
        <div class="col-md-4 col-sm-12 mb-2 mb-md-0">
          <div class="input-group">
            <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownProvince" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedRegion">
              <span class="d-inline-block text-truncate" style="max-width: 100px;">{{ selectedProvinceName || 'จังหวัด' }}</span>
            </button>
            <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownProvince">
              <li v-for="province in filteredProvinces" :key="province.id">
                <a class="dropdown-item" href="#" @click.prevent="selectProvince(province)">{{ province.name_th }}</a>
              </li>
            </ul>
            <span class="input-group-text col-5">จังหวัด</span>
          </div>
        </div>

        <!-- Dropdown อำเภอ -->
        <div class="col-md-4 col-sm-12 mb-2 mb-md-0">
          <div class="input-group">
            <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownAmphur" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedProvince">
              <span class="d-inline-block text-truncate">{{ selectedAmphurName || 'อำเภอ' }}</span>
            </button>
            <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownAmphur">
              <li v-for="amphur in filteredAmphurs" :key="amphur.id">
                <a class="dropdown-item" href="#" @click.prevent="selectAmphur(amphur)">{{ amphur.name_th }}</a>
              </li>
            </ul>
            <span class="input-group-text col-5">อำเภอ</span>
          </div>
        </div>

        <!-- Dropdown ตำบล -->
        <div class="col-md-4 col-sm-12">
          <div class="input-group">
            <button class="btn btn-outline-secondary dropdown-toggle col-7 d-flex align-items-center justify-content-center" type="button" id="dropdownTambon" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedAmphur">
              <span class="d-inline-block text-truncate">{{ selectedTambonName || 'ตำบล' }}</span>
            </button>
            <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownTambon">
              <li v-for="tambon in filteredTambons" :key="tambon.id">
                <a class="dropdown-item" href="#" @click.prevent="selectTambon(tambon)">{{ tambon.name_th }}</a>
              </li>
            </ul>
            <span class="input-group-text col-5">ตำบล</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    locationData: {
      type: Object,
      default: () => ({
        region: '',
        province: '',
        amphur: '',
        tambon: ''
      })
    }
  },
  data() {
    return {
      selectedRegion: null,
      selectedProvince: null,
      selectedAmphur: null,
      selectedTambon: null,
      regions: [],
      provinces: [],
      amphurs: [],
      tambons: [],
      filteredProvinces: [],
      filteredAmphurs: [],
      filteredTambons: []
    };
  },
  computed: {
    selectedRegionName() {
      return this.selectedRegion ? this.selectedRegion.name : '';
    },
    selectedProvinceName() {
      return this.selectedProvince ? this.selectedProvince.name_th : '';
    },
    selectedAmphurName() {
      return this.selectedAmphur ? this.selectedAmphur.name_th : '';
    },
    selectedTambonName() {
      return this.selectedTambon ? this.selectedTambon.name_th : '';
    }
  },
  methods: {
    selectRegion(region) {
      this.selectedRegion = region;
      this.filteredProvinces = this.provinces.filter(province => province.geography_id === region.id);
      this.selectedProvince = null;
      this.selectedAmphur = null;
      this.selectedTambon = null;
      this.filteredAmphurs = [];
      this.filteredTambons = [];
      this.emitLocationData();
    },
    selectProvince(province) {
      this.selectedProvince = province;
      this.filteredAmphurs = this.amphurs.filter(amphur => amphur.province_id === province.id);
      this.selectedAmphur = null;
      this.selectedTambon = null;
      this.filteredTambons = [];
      this.emitLocationData();
    },
    selectAmphur(amphur) {
      this.selectedAmphur = amphur;
      this.filteredTambons = this.tambons.filter(tambon => tambon.amphure_id === amphur.id);
      this.selectedTambon = null;
      this.emitLocationData();
    },
    selectTambon(tambon) {
      this.selectedTambon = tambon;
      this.emitLocationData();
    },
    emitLocationData() {
      const locationData = {
        region: this.selectedRegion ? this.selectedRegion.name : null,
        province: this.selectedProvince ? this.selectedProvince.name_th : null,
        amphur: this.selectedAmphur ? this.selectedAmphur.name_th : null,
        tambon: this.selectedTambon ? this.selectedTambon.name_th : null
      };
      this.$emit('update:locationData', locationData);
    },
    loadRegions() {
      axios.get('/thai_geographies.json')
        .then(response => {
          this.regions = response.data;
          this.setInitialValues();
        })
        .catch(error => {
          console.error('Error loading regions:', error);
        });
    },
    loadProvinces() {
      axios.get('/thai_provinces.json')
        .then(response => {
          this.provinces = response.data;
          this.setInitialValues();
        })
        .catch(error => {
          console.error('Error loading provinces:', error);
        });
    },
    loadAmphurs() {
      axios.get('/thai_amphures.json')
        .then(response => {
          this.amphurs = response.data;
          this.setInitialValues();
        })
        .catch(error => {
          console.error('Error loading amphurs:', error);
        });
    },
    loadTambons() {
      axios.get('/thai_tambons.json')
        .then(response => {
          this.tambons = response.data;
          this.setInitialValues();
        })
        .catch(error => {
          console.error('Error loading tambons:', error);
        });
    },
    setInitialValues() {
      // ตรวจสอบว่าข้อมูลถูกโหลดเสร็จสมบูรณ์ และมี locationData ส่งเข้ามาหรือไม่
      console.log(this.locationData);
      if (this.regions.length && this.provinces.length && this.amphurs.length && this.tambons.length) {
        if (this.locationData.region) {
          const region = this.regions.find(r => r.name === this.locationData.region);
          if (region) {
            this.selectRegion(region);
          }
        }
        if (this.locationData.province) {
          const province = this.provinces.find(p => p.name_th === this.locationData.province);
          if (province) {
            this.selectProvince(province);
          }
        }
        if (this.locationData.amphur) {
          const amphur = this.amphurs.find(a => a.name_th === this.locationData.amphur);
          if (amphur) {
            this.selectAmphur(amphur);
          }
        }
        if (this.locationData.tambon) {
          const tambon = this.tambons.find(t => t.name_th === this.locationData.tambon);
          if (tambon) {
            this.selectTambon(tambon);
          }
        }
      }
    }
  },
  mounted() {
    console.log(this.locationData);
    this.loadRegions();
    this.loadProvinces();
    this.loadAmphurs();
    this.loadTambons();
  }
};
</script>

<style scoped>
.limited-dropdown {
  max-height: 200px;
  overflow-y: auto;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
