<template>
  <!-- Footer -->
  <footer class="footer mt-auto py-5">
    <div class="container">
      <div class="row text-white mb-4">
        <!-- โลโก้และคำอธิบาย -->
        <div class="col-md-4 text-center mb-5">
          <img :srcset="logoUrl" alt="Kaizub Logo" class="footer-logo mb-2" loading="lazy">
          <h5 class="fw-bold mt-4">Kaizub | ขายทรัพย์</h5>
          <p><i class="fas fa-envelope me-2"></i><a :href="'mailto:' + contactEmail" class="text-decoration-none text-white">{{ contactEmail }}</a></p>
        </div>
        <!-- ลิงก์โซเชียลมีเดีย -->
        <div class="col-md-4 text-center d-flex align-items-center justify-content-center mb-5">
          <div class="social-icons mb-2">
            <a href="https://www.facebook.com/kaizubofficial" class="text-white me-5" target="_blank"><i class="fab fa-facebook fa-2x"></i></a>
            <a href="https://line.me/R/ti/p/@709mhewi" class="text-white me-5" target="_blank"><i class="fab fa-line fa-2x"></i></a>
          </div>
        </div>
        <!-- QR Code และ TikTok -->
        <div class="col-md-4 mb-4 text-center mb-5">
            <img :srcset="lineQRCodeUrl" alt="Line QR Code" class="img-fluid mb-3" style="max-width: 150px;">
            <p>@709mhewi</p>
        </div>
      </div>
      <span>Copyright &copy;  2024 Kaizub.com. All Rights Reserved. Developed by Kaizub</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSection',
  data() {
    return {
      logoUrl: 'img/logoOnly.webp', // ใส่ที่อยู่ของโลโก้ KaiTdin
      lineQRCodeUrl: 'img/line-qrcode.webp', // ใส่ที่อยู่ของ QR Code Line
      tiktokLogoUrl: 'path/to/tiktok-logo.png', // ใส่ที่อยู่ของโลโก้ TikTok
      contactEmail: 'kaizub.com@hotmail.com', // อีเมลสำหรับติดต่อ
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #2d1f65; /* สีพื้นหลังของ Footer ให้เหมือนในภาพ */
  color: #fff; /* สีข้อความ */
  padding: 40px 0; /* ระยะห่างของข้อความใน Footer */
  text-align: center;
}

.footer-logo {
  max-width: 100px; /* กำหนดขนาดของโลโก้ */
}

.social-icons a {
  color: #fff;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #efbf04; /* เปลี่ยนสีเมื่อ hover */
}
</style>
