// src/axios.js
import axios from 'axios';
import store from '@/store';
import { getCsrfToken, resetCsrfToken } from '@/csrf'; // นำเข้า CSRF functions

const BASE_URL = process.env.VUE_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// เพิ่ม request interceptor เพื่อแนบ CSRF token
axiosInstance.interceptors.request.use(
  async config => {
    // แนบ CSRF token ในคำขอที่ไม่ใช่ GET, HEAD, OPTIONS
    if (!['get', 'head', 'options'].includes(config.method)) {
      const csrfToken = await getCsrfToken();
      if (csrfToken) {
        config.headers['CSRF-Token'] = csrfToken;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    // จัดการกับ Invalid CSRF token
    if (
      error.response &&
      error.response.status === 403 &&
      error.response.data.message === 'Invalid CSRF token' &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // รีเซ็ต CSRF token และร้องขอใหม่
      resetCsrfToken();
      const csrfToken = await getCsrfToken();

      // ปรับปรุง CSRF token ใน headers ของคำขอเดิม
      originalRequest.headers['CSRF-Token'] = csrfToken;

      // ลองส่งคำขอเดิมอีกครั้ง
      return axiosInstance(originalRequest);
    }

    // จัดการกับ Unauthorized (JWT หมดอายุ)
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // เรียก refresh token endpoint เพื่อรับ JWT ใหม่
        await axiosInstance.post('/auth/refresh-token');

        // รีเซ็ต CSRF token เพราะอาจเปลี่ยนไปหลังจากรีเฟรชโทเค็น
        resetCsrfToken();
        const csrfToken = await getCsrfToken();

        // ปรับปรุง CSRF token ใน headers ของคำขอเดิม
        originalRequest.headers['CSRF-Token'] = csrfToken;

        // ลองส่งคำขอเดิมอีกครั้ง
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        const wasLoggedIn = store.getters.isLoggedIn;

        // อัปเดต Vuex Store
        store.commit('setLoggedIn', false);
        store.commit('setUserId', null);
        store.commit('setCredit', null);
        store.commit('setName', null);
        store.commit('setTel', null);
        store.commit('setLine', null);
        store.commit('setEmail', null);

        // ตั้งค่า sessionExpired เป็น true เฉพาะเมื่อผู้ใช้เคยล็อกอิน
        if (wasLoggedIn) {
          store.commit('setSessionExpired', true);
        }

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
