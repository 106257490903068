<template>
  <div class="container">
    <!-- Dropdown ภูมิภาค -->
    <div class="dropdown mb-2 col-12">
      <button class="btn custom-outline-btn dropdown-toggle col-12" type="button" id="dropdownRegion" data-bs-toggle="dropdown" aria-expanded="false">
        {{ selectedRegionName || 'ภูมิภาค' }}
      </button>
      <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownRegion">
        <li v-for="region in regions" :key="region.id">
          <a class="dropdown-item" href="#" @click.prevent="selectRegion(region)">{{ region.name }}</a>
        </li>
      </ul>
    </div>

    <!-- Dropdown จังหวัด -->
    <div class="dropdown mb-2 col-12">
      <button class="btn custom-outline-btn dropdown-toggle col-12" type="button" id="dropdownProvince" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedRegion">
        {{ selectedProvinceName || 'จังหวัด' }}
      </button>
      <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownProvince">
        <li v-for="province in filteredProvinces" :key="province.id">
          <a class="dropdown-item" href="#" @click.prevent="selectProvince(province)">{{ province.name_th }}</a>
        </li>
      </ul>
    </div>

    <!-- Dropdown อำเภอ -->
    <div class="dropdown mb-2 col-12">
      <button class="btn custom-outline-btn dropdown-toggle col-12" type="button" id="dropdownAmphur" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedProvince">
        {{ selectedAmphurName || 'อำเภอ' }}
      </button>
      <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownAmphur">
        <li v-for="amphur in filteredAmphurs" :key="amphur.id">
          <a class="dropdown-item" href="#" @click.prevent="selectAmphur(amphur)">{{ amphur.name_th }}</a>
        </li>
      </ul>
    </div>

    <!-- Dropdown ตำบล -->
    <div class="dropdown mb-2 col-12">
      <button class="btn custom-outline-btn dropdown-toggle col-12" type="button" id="dropdownTambon" data-bs-toggle="dropdown" aria-expanded="false" :disabled="!selectedAmphur">
        {{ selectedTambonName || 'ตำบล' }}
      </button>
      <ul class="dropdown-menu limited-dropdown col-12" aria-labelledby="dropdownTambon">
        <li v-for="tambon in filteredTambons" :key="tambon.id">
          <a class="dropdown-item" href="#" @click.prevent="selectTambon(tambon)">{{ tambon.name_th }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      selectedRegion: null,
      selectedProvince: null,
      selectedAmphur: null,
      selectedTambon: null,
      regions: [], // ข้อมูลภาค
      provinces: [], // ข้อมูลจังหวัดทั้งหมด
      amphurs: [], // ข้อมูลอำเภอทั้งหมด
      tambons: [], // ข้อมูลตำบลทั้งหมด
      filteredProvinces: [],
      filteredAmphurs: [],
      filteredTambons: []
    };
  },
  computed: {
    selectedRegionName() {
      return this.selectedRegion ? this.selectedRegion.name : '';
    },
    selectedProvinceName() {
      return this.selectedProvince ? this.selectedProvince.name_th : '';
    },
    selectedAmphurName() {
      return this.selectedAmphur ? this.selectedAmphur.name_th : '';
    },
    selectedTambonName() {
      return this.selectedTambon ? this.selectedTambon.name_th : '';
    }
  },
  methods: {
    emitLocation() {
      this.$emit('locationSelected', {
        region: this.selectedRegion ? this.selectedRegion.name : '',
        province: this.selectedProvince ? this.selectedProvince.name_th : '',
        amphur: this.selectedAmphur ? this.selectedAmphur.name_th : '',
        tambon: this.selectedTambon ? this.selectedTambon.name_th : '',
      });
    },
    resetLocation() {
        this.selectedRegion = null;
        this.selectedProvince = null;
        this.selectedAmphur = null;
        this.selectedTambon = null;
        this.filteredProvinces = [];
        this.filteredAmphurs = [];
        this.filteredTambons = [];
    },
    selectRegion(region) {
      this.selectedRegion = region;
      this.filteredProvinces = this.provinces.filter(province => province.geography_id === region.id);
      this.selectedProvince = null;
      this.selectedAmphur = null;
      this.selectedTambon = null;
      this.filteredAmphurs = [];
      this.filteredTambons = [];
      this.emitLocation(); // ส่งข้อมูลตำแหน่งที่เลือกกลับไป
    },
    selectProvince(province) {
      this.selectedProvince = province;
      this.filteredAmphurs = this.amphurs.filter(amphur => amphur.province_id === province.id);
      this.selectedAmphur = null;
      this.selectedTambon = null;
      this.filteredTambons = [];
      this.emitLocation(); // ส่งข้อมูลตำแหน่งที่เลือกกลับไป
    },
    selectAmphur(amphur) {
      this.selectedAmphur = amphur;
      this.filteredTambons = this.tambons.filter(tambon => tambon.amphure_id === amphur.id);
      this.selectedTambon = null;
      this.emitLocation(); // ส่งข้อมูลตำแหน่งที่เลือกกลับไป
    },
    selectTambon(tambon) {
      this.selectedTambon = tambon;
      this.emitLocation(); // ส่งข้อมูลตำแหน่งที่เลือกกลับไป
    },
    loadRegions() {
      axios.get('/thai_geographies.json') // โหลดข้อมูลภูมิภาค
        .then(response => {
          this.regions = response.data;
        })
        .catch(error => {
          console.error('Error loading regions:', error);
        });
    },
    loadProvinces() {
      axios.get('/thai_provinces.json') // โหลดข้อมูลจังหวัด
        .then(response => {
          this.provinces = response.data;
        })
        .catch(error => {
          console.error('Error loading provinces:', error);
        });
    },
    loadAmphurs() {
      axios.get('/thai_amphures.json') // โหลดข้อมูลอำเภอ
        .then(response => {
          this.amphurs = response.data;
        })
        .catch(error => {
          console.error('Error loading amphurs:', error);
        });
    },
    loadTambons() {
      axios.get('/thai_tambons.json') // โหลดข้อมูลตำบล
        .then(response => {
          this.tambons = response.data;
        })
        .catch(error => {
          console.error('Error loading tambons:', error);
        });
    }
  },
  mounted() {
    this.loadRegions();  // โหลดข้อมูลภูมิภาค
    this.loadProvinces(); // โหลดข้อมูลจังหวัด
    this.loadAmphurs();   // โหลดข้อมูลอำเภอ
    this.loadTambons();   // โหลดข้อมูลตำบล
  }
};
</script>

<style scoped>
.limited-dropdown {
  width: 100%; /* ขยายความกว้างให้เท่ากับปุ่ม */
  max-height: 200px;
  overflow-y: auto;
}
</style>
