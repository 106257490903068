<template>
    <div class="container mt-5" v-if="property && Object.keys(property).length">
        <div class="row">
            <div class="col-12 col-md-9">
                <article>
                    <header class="mb-4">
                        <h3 class="fw-bolder mb-1 text-custom">{{ property.Title }}</h3>
                        <div class="text-muted fst-italic my-2">ลงประกาศเมื่อ {{ formattedDate(property.createdAt) }} </div>
                        <div class="text-muted fst-italic my-2"><i class="fa-solid fa-eye me-2"></i>การเข้าชม {{ this.property.engage}} ครั้ง</div>
                        <!-- Post categories-->
                        <!-- <a class="badge bg-secondary text-decoration-none link-light me-1" href="#!">เชียงราย</a>
                        <a class="badge bg-secondary text-decoration-none link-light me-1" href="#!">บ้านแฝด</a>
                        <a class="badge bg-secondary text-decoration-none link-light me-1" href="#!">ราคาถูก</a> -->
                    </header>
                    <PropertyDetailImg :images="property.images" />
                    <section class="mb-5 mt-5" style="text-align: left;">
                        <div class="card shadow" style="border-color: #2d1f65;">
                            <div class="container p-5">
                                <div class="badges text-center text-md-start mb-4">
                                    <span class="badge bg-custom me-2" style="font-weight: 400;">{{ this.property.Location.Province }}</span>
                                    <span class="badge bg-custom me-2" style="font-weight: 400;">{{ this.property.Location.Amphur }}</span>
                                    <span class="badge bg-custom me-2" style="font-weight: 400;">{{ this.property.Location.Tambon }}</span>
                                </div>
                                <div v-if="formattedPositions.length > 0" class="badges text-center text-md-start mb-4">
                                    <span class="badge bg-custom me-2" style="font-weight: 400;">{{ this.property.Category }}</span>
                                    <span v-for="(position, index) in formattedPositions" :key="index" class="badge bg-custom me-2" style="font-weight: 400;">
                                        {{ position }}
                                    </span>
                                </div>
                                <h1 class="fw-bolder py-3 px-2 text-primary">ราคา {{ this.Price }} B</h1>
                                <p id="description" class="py-2 px-2" v-html="formattedDetail"></p>
                            </div>
                        </div>
                    </section>
                </article>
                <section v-if="mapIframeSrc" class="mb-5">
                    <div class="card shadow overflow-hidden" style="border-color: #2d1f65;">
                        <div class="card-header bg-success text-white">ตำแหน่งที่ตั้ง</div>
                        <div class="embed-responsive embed-responsive-16by9" style="height:500px;">
                            <iframe
                                
                                :src="mapIframeSrc"
                                width="100%"
                                height="100%"
                                style="border:0;"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                    </div>
                </section>
            </div>
            <!-- Side widgets-->
            <div class="col-12 col-md-3">
                <!-- Search widget-->
                <div class="card shadow mb-4" style="border-color: #2d1f65;">
                    <div class="card-header bg-custom text-white">ผู้ลงประกาศ</div>
                    <div class="card-body">
                        
                        <div class="row">
                            <div class="mb-3" id="imgProfile">
                                <img src="/img/user-profile-icon.png" alt="Profile" class="rounded-circle img-fluid" style="object-fit: cover; max-width: 60px; max-height: 60px;">
                                <p class="my-3 text-primary">{{ name }}</p>
                                
                                <div class="mt-3">
                                    <button 
                                        @click="toggleContactDropdown" 
                                        :class="['btn', 'btn-contact', 'w-100', 'd-flex', 'align-items-center', 'justify-content-center', { active: isContactVisible }]"
                                    >
                                        <i class="fa-solid fa-phone me-2"></i>
                                        <span>{{ isContactVisible ? 'ซ่อนข้อมูล' : 'แสดงข้อมูลติดต่อ' }}</span>
                                    </button>
                                    
                                    <div v-if="isContactVisible" class="dropdown-menu show mt-2 p-3" style="border-radius: 10px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);">
                                        <div class="d-flex align-items-center justify-content-center mb-2">
                                            <i class="fa-solid fa-mobile-screen-button me-2" style="color: #2d1f65;"></i>
                                            <span style="color: #2d1f65;">{{ formattedTel }}</span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center mb-2">
                                            <i class="fa-brands fa-line me-2" style="color: #06c755;"></i>
                                            <span style="color: #06c755;">{{ formattedLine }}</span>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center">
                                            <i class="fa-solid fa-envelope me-2" style="color: #310bd8;"></i>
                                            <span style="color: #310bd8;">{{ formattedEmail }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Categories widget-->
                <div class="card shadow mb-4" style="border-color: #2d1f65;">
                    <div class="card-header bg-custom text-white">รายละเอียด</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 ">
                                <div class="col-md-10 mx-auto">
                                    <div class="d-flex justify-content-between align-items-center my-2 text-custom-blue">
                                        <h6 class="mb-0">ราคา</h6>
                                        <p class="mb-0">{{ this.Price }}</p>
                                    </div>
                                    <hr>
                                    <div class="d-flex flex-column my-2 text-success">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <h6 class="mb-3 text-custom-blue">ขนาดที่ดิน</h6>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>ไร่</p>
                                            <p>{{ this.property.Scale.ScaleRai }} ไร่</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>งาน</p>
                                            <p>{{ this.property.Scale.ScaleNgan }} งาน</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>ตารางวา</p>
                                            <p>{{ this.property.Scale.ScaleWah }} ตร.ว</p>
                                        </div>
                                    </div>
                                    
                                    <div class="d-flex flex-column my-2 text-success" v-if="property.PropertyType !== 'ที่ดิน' && property.houseDetails">
                                        <hr>
                                        <div class="d-flex justify-content-between align-items-center text-custom-blue">
                                            <h6 class="mb-3">ลักษณะบ้าน</h6>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>จำนวนห้องนอน</p>
                                            <p>{{ this.property.houseDetails.bedrooms }} ห้อง</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>จำนวนห้องน้ำ</p>
                                            <p>{{ this.property.houseDetails.bathrooms }} ห้อง</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>จำนวนชั้น</p>
                                            <p>{{ this.property.houseDetails.floors }} ชั้น</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>ขนาดที่ดิน</p>
                                            <p>{{ this.property.houseDetails.landScale }} ตร.ว</p>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>พื้นที่ใช้สอย</p>
                                            <div v-if="this.property.houseDetails.houseScale">
                                                <p>{{ this.property.houseDetails.houseScale }} ตร.ม</p>
                                            </div>
                                            <div v-else>
                                                <p>ไม่ทราบ</p>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <p>จำนวนพื้นที่จอดรถ</p>
                                            <div v-if="this.property.houseDetails.carsSlot">
                                                <p>{{ this.property.houseDetails.carsSlot}} คัน</p>
                                            </div>
                                            <div v-else>
                                                <p>ไม่ทราบ</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <!-- Side widget-->
                <div v-if="formattedFacilities.length > 0">
                    <div class="card shadow mb-4" style="border-color: #2d1f65;">
                        <div class="card-header bg-custom text-white">สิ่งอำนวยความสะดวก</div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 ">
                                    <div class="col-md-12">
                                        <div class="d-flex flex-column my-2">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <h6 class="mb-3"></h6>
                                            </div>
                                            <div v-if="formattedFacilities.length > 0">
                                                <div v-for="facility in formattedFacilities" class="text-center text-custom-blue" :key="facility" style="text-align: left;">
                                                    <p>{{ facility }}</p>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <p>ไม่มี</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <p>กำลังโหลดข้อมูล...</p>
    </div>
</template>
  
  <script>
  import axios from '@/axios.js';
  import PropertyDetailImg from './PropertyDetailImg.vue';
  //import SearchBar from '../components/SearchBar.vue';
  //import CategoryList from './CategoryList.vue'
  import { WOW } from 'wowjs';
  import 'animate.css';

  export default {
    name: 'PropertyDetail',
    components: {
        PropertyDetailImg,
    },
    data() {
        return {
            property: {},
            name: '',
            email:'',
            tel:'',
            line:'',
            Price:'',
            FacilitiesToThai: {
                furniture: 'เฟอร์นิเจอร์',
                airConditioner: 'เครื่องปรับอากาศ',
                digitalLock: 'ประตูดิจิตอล',
                tv: 'โทรทัศน์',
                refrigerator: 'ตู้เย็น',
                microwave: 'ไมโครเวฟ',
                swimmingpool: 'สระว่ายน้ำ',
                landlinePhone: 'โทรศัพท์บ้าน',
                hotWaterMachine: 'เครื่องทำน้ำอุ่น',
                bathtub: 'อ่างอาบน้ำ',
                kitchen: 'เตาปรุงอาหาร',
                cookerhood: 'เครื่องดูดควัน',
                fitness: 'ฟิตเนส',
                fan: 'พัดลม',
            },
            isContactVisible: false,
        };
    },
    methods: {
        async fetchUserDetails(userId) {
            try {
                // ยิง API เพื่อดึงข้อมูลผู้ใช้ตาม userId
                const response = await axios.get(`/auth/user/${userId}`);
                if (response.data.name) {
                    this.name = 'คุณ ' + response.data.name;
                } else {
                    this.name = 'ผู้ลงประกาศไม่ทราบชื่อ';
                }
                this.tel = response.data.Telnum;
                this.line = response.data.Line;
                this.email = response.data.email;
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        },
        formattedPrice(price) {
            if (!price) return 'สอบถามผู้ลงประกาศ';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
            return Number(price).toLocaleString();  // ใส่คอมม่าในตัวเลข
        },
        toggleContactDropdown() {
            this.isContactVisible = !this.isContactVisible; // สลับสถานะการแสดงข้อมูลติดต่อ
        }
    },
    mounted() {
        new WOW({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true
        }).init();
    },
    computed: {
        formattedPositions() {
            return this.property.Position ? this.property.Position.split(',').map(position => position.trim()) : [];
        },
        formattedDate() {
            return function(dateString) {
                if (!dateString) return ''; // ตรวจสอบว่ามีค่าวันที่
                const date = new Date(dateString); // แปลงค่าเป็น Date object
                if (isNaN(date.getTime())) return 'Invalid Date'; // ตรวจสอบว่าค่าเป็นวันที่ถูกต้อง
                const options = { year: 'numeric', month: 'short', day: 'numeric' };
                return date.toLocaleDateString('th-TH', options);
            };
        },
        formattedDetail() {
            // แปลง newline ให้เป็น <br> เพื่อให้แสดงผลบรรทัดใหม่ใน HTML
            return this.property.Detail ? this.property.Detail.replace(/\n/g, '<br>') : '';
        },
        formattedFacilities() {
            if (!this.property.facilities) {
                return [];
            }

            return this.property.facilities
                .filter(facility => facility in this.FacilitiesToThai)
                .map(facility => this.FacilitiesToThai[facility]);
        },
        mapIframeSrc() {
            if (this.property && this.property.Latitude && this.property.Longitude) {
                const lat = this.property.Latitude;
                const lng = this.property.Longitude;
                return `https://www.google.com/maps?q=${lat},${lng}&hl=th&z=15&output=embed`;
            } else {
                return '';
            }
        },
        formattedTel() {
            // ถ้ามีข้อมูลเบอร์โทรจาก user ให้ใช้ข้อมูลนั้นก่อน
            return this.tel ? this.tel : (this.property.Telnum ? this.property.Telnum : 'ไม่ระบุหมายเลขโทรศัพท์');
        },
        formattedLine() {
            // ถ้ามีข้อมูล Line จาก user ให้ใช้ข้อมูลนั้นก่อน
            return this.line ? this.line : (this.property.Line ? this.property.Line : 'ไม่ระบุ LINE ID');
        },
        formattedEmail() {
            // ใช้ข้อมูลอีเมลจากผู้ใช้ก่อน ถ้าไม่มีให้แสดงว่าไม่ระบุ
            return this.email ? this.email : 'ไม่ระบุอีเมล';
        }
    },
    async created() {
        const propertyId = this.$route.params.id;
        try {
            const response = await axios.get(`/api/property/detail/${propertyId}`);
            this.property = response.data;
            this.Price = this.formattedPrice(this.property.Price);
            console.log(this.property);
            console.log(this.property.engage);
            // เมื่อดึงข้อมูล property เสร็จแล้ว ให้เรียกใช้ fetchUserDetails เพื่อตรวจสอบข้อมูลผู้ใช้
            if (this.property.userId) {
                this.fetchUserDetails(this.property.userId);
            }

        } catch (error) {
            console.error('Error fetching property:', error);
            this.property = null;
        }
    }
  };
  </script>
  
  <style scoped>
  
@import 'animate.css';

    .card {
        border: 1px solid #dee2e6;
        border-radius: 0.375rem;
    }

    #pagination1{
        margin-top: 10px;
        justify-content: center;
    }
    @media (max-width: 768px) {
        
    }

    .dropdown-menu {
        background-color: #ffffff;
        border: 1px solid #dddddd;
        width: 100%;
        max-width: 300px; /* ปรับตามขนาดที่ต้องการ */
    }

    .btn-contact {
        background-color: #fff;
        border-color: #2d1f65;
        color: #2d1f65;
        transition: background-color 0.3s ease;
    }

    .btn-contact:hover {
        background-color: #2d1f65;
        color: #fff;
    }

    .btn-contact.active {
        background-color: #2d1f65;
        color: #fff;
    }
    
    .text-custom-blue {
        color: #310bd8;
    }
    .badge.bg-custom {
        font-size: 0.75rem; /* ปรับขนาดฟอนต์ให้ใหญ่ขึ้น */
        padding: 10px 10px; /* ปรับ padding เพื่อให้ badge ใหญ่ขึ้น */
        border-radius: 0.5rem; /* ปรับความโค้งของมุม */
        background-color: #2d1f65; /* สีพื้นหลัง */
        color: white; /* สีข้อความ */
    }
    #description {
        font-size: large;
    }
    @media (max-width: 768px) {
        #description {
            font-size: medium;
        }
    }
    
  </style>
  