<template>
  <div class="container">
    <section>
      <form id="formLogin" @submit.prevent="form_submit" method="post">
        <div class="mt-5 col-12 col-md-6 mb-5 mx-auto">
          <div class="card">
            <div class="card-body mt-3">
              <img src="../img/logo.png" alt="logo" width="30%" height="30%">
              <div class="mt-3">
                <h4>เข้าสู่ระบบ</h4>
              </div>
              
              <!-- ส่วนนี้สำหรับแสดงข้อความแจ้งเตือน -->
              <div v-if="loginError" class="alert alert-danger mt-3" role="alert">
                {{ loginError }}
              </div>

              <div class="container px-md-5 my-5">
                <div class="row text-start">
                  <label for="username" class="col-sm-3 col-form-label">ชื่อผู้ใช้</label>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <input v-model="username" type="text" class="form-control" id="username" placeholder="ชื่อผู้ใช้หรืออีเมล์" required>
                  </div>
                </div>

                <div class="row text-start">
                  <label for="password" class="col-sm-3 col-form-label">รหัสผ่าน</label>
                </div>
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <input v-model="password" type="password" class="form-control" id="password" placeholder="รหัสผ่าน" required>
                  </div>
                </div>

                <div class="mb-3 row">
                  <div class="text-center">
                    <button type="submit" class="btn btn-primary col-12">เข้าสู่ระบบ</button>
                  </div>
                </div>
                <div class="mb-3 row">
                  <div class="text-center">
                    <button class="btn btn-outline-secondary col-12" @click="toRegisterPage">สมัครสมาชิก</button>
                  </div>
                </div>
                <div class="mb-3 row">
                  <div class="col d-flex justify-content-between">
                    <a href="/forgotpassword/" class="text-decoration-none">ลืมรหัสผ่าน?</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      username: '',
      password: '',
      loginError: ''
    };
  },
  methods: {
    ...mapActions([
      'login'
    ]),
    async form_submit() {
      try {
        this.loginError = '';
        await this.login({
          username: this.username,
          password: this.password
        });
        //login success
        this.$router.push('/');
      } catch (error) {
        console.error('Login fail:', error);
        this.loginError = 'ล็อกอินไม่สำเร็จ กรุณาตรวจสอบชื่อผู้ใช้และรหัสผ่าน';
      }
    },
    toRegisterPage(){
      this.$router.push('/register');
    }
  }
};
</script>

  
  <style scoped>
 
  </style>