<template>
    <div class="container">
      <h4 class="text-center">
        Error 404: not found
      </h4>
    </div>
</template>
  
  <script>
  export default {
    name: 'ErrorPage404',
  };
  </script>
  
  <style scoped>
  .sect{
    margin:100px
  }
  </style>