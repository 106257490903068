<!-- navbar.vue -->
<template>
    <main class="bg-fade">

    <!-- Search Bar -->
    <div>
        <SearchBar/>
    </div>

    <div class="container mt-3">
        <EmblaCarouselBanner/> 
    </div>

    <!-- Category -->
    <div class="container mt-3">
        <CategoryList/> 
    </div>

    <div class="container-xxl py-5">
        <div class="container">
            <!-- First Post -->
            <div 
                v-if="itemEx.length >= 1" 
                :key="0" 
                class="bg-white position-relative py-5"
            >
                <div class="exclusive-badge-right">
                    <i class="fa-brands fa-square-web-awesome-stroke me-2 fa-xl"></i>Exclusive
                </div>
                <div class="row align-items-center" style="--bs-gutter-x: 3rem; --bs-gutter-y: 6rem;">
                    <div class="col-lg-6">
                        <div class="about-img-left position-relative overflow-hidden p-5 pe-0">
                            <img class="img-fluid w-100" :src="itemEx[0].images?.[0]?.url || 'https://placehold.co/600x400?text=Exclusive'" alt="Exclusive Image">
                        </div>  
                    </div>
                    <div class="col-lg-6">
                        <h4 class="mt-md-5 mb-4">{{ itemEx[0].Title }}</h4>
                        <div class="badges text-center mb-2">
                            <span class="badge bg-custom me-2" style="font-weight: 400;">{{ itemEx[0].PropertyType }}</span>
                            <span class="badge bg-custom me-2" style="font-weight: 400;">{{ itemEx[0].Category }}</span>
                            <div v-if="itemEx[0].Category === 'ที่ดิน'">
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    {{ itemEx[0].Scale.ScaleRai }} ไร่
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    {{ itemEx[0].Scale.ScaleNgan }} งาน
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    {{ itemEx[0].Scale.ScaleWah }} ตร.ว
                                </span>
                            </div>
                            <div v-else>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    <i class="fa fa-bed text-white me-1"></i>
                                    {{ itemEx[0].houseDetails.bedrooms }}
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    <i class="fa fa-bath text-white me-1"></i>
                                    {{ itemEx[0].houseDetails.bathrooms }}
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    <i class="fa-solid fa-stairs me-1"></i>
                                    {{ itemEx[0].houseDetails.floors }}
                                </span>
                            </div>
                        </div>
                        <h6 class="py-2 px-2" v-html="formattedDetail1"></h6>
                        <h6 class="mt-3 mb-2"><i class="fa fa-check text-primary me-3"></i>{{ itemEx[0].Position }}</h6>
                        <router-link :to="{ name: 'PropertyDetail', params: { id: itemEx[0]._id } }" class="text-decoration-none text-dark">
                            <a class="btn custom-btn py-3 px-5 my-4" href="">ดูรายละเอียดเพิ่มเติม</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <!-- Second Post -->
            <div 
                v-if="itemEx.length >= 2" 
                :key="1" 
                class="bg-white position-relative py-5 mt-5"
            >
                <div class="exclusive-badge-left">
                    <i class="fa-brands fa-square-web-awesome-stroke me-2 fa-xl"></i>Exclusive
                </div>
                <div class="row align-items-center" style="--bs-gutter-x: 3rem; --bs-gutter-y: 6rem;">
                    <div class="col-lg-6 order-lg-2">
                        <div class="about-img-right position-relative overflow-hidden p-5 pe-0">
                            <img class="img-fluid w-100" :src="itemEx[1].images?.[0]?.url || 'https://placehold.co/600x400?text=Exclusive'" alt="Exclusive Image">
                        </div>  
                    </div>
                    <div class="col-lg-6 order-lg-1">
                        <h4 class="mt-md-5 mb-2">{{ itemEx[1].Title }}</h4>
                        <div class="badges text-center mb-2">
                            <span class="badge bg-custom me-2" style="font-weight: 400;">{{ itemEx[1].PropertyType }}</span>
                            <span class="badge bg-custom me-2" style="font-weight: 400;">{{ itemEx[1].Category }}</span>
                            <div v-if="itemEx[1].Category === 'ที่ดิน'">
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    {{ itemEx[1].Scale.ScaleRai }} ไร่
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    {{ itemEx[1].Scale.ScaleNgan }} งาน
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    {{ itemEx[1].Scale.ScaleWah }} ตร.ว
                                </span>
                            </div>
                            <div v-else>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    <i class="fa fa-bed text-white me-1"></i>
                                    {{ itemEx[1].houseDetails.bedrooms }}
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    <i class="fa fa-bath text-white me-1"></i>
                                    {{ itemEx[1].houseDetails.bathrooms }}
                                </span>
                                <span class="badge bg-custom me-2" style="font-weight: 400;">
                                    <i class="fa-solid fa-stairs me-1"></i>
                                    {{ itemEx[1].houseDetails.floors }}
                                </span>
                            </div>
                        </div>
                        <h6 class="py-2 px-2" v-html="formattedDetail2"></h6>
                        <h6 class="mt-3 mb-2"><i class="fa fa-check text-primary me-3"></i>{{ itemEx[1].Position }}</h6>
                        <router-link :to="{ name: 'PropertyDetail', params: { id: itemEx[1]._id } }" class="text-decoration-none text-dark">
                            <a class="btn custom-btn py-3 px-5 my-4" href="">ดูรายละเอียดเพิ่มเติม</a>
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-if="itemEx.length === 0"  class="bg-white position-relative py-5">
                <div class="exclusive-badge-right">
                    <i class="fa-brands fa-square-web-awesome-stroke me-2 fa-xl"></i>Exclusive
                </div>
                <div class="row align-items-center" style="--bs-gutter-x: 3rem; --bs-gutter-y: 6rem;">
                    <div class="col-lg-6">
                        <div class="about-img-left position-relative overflow-hidden p-5 pe-0">
                            <img class="img-fluid w-100" :src="'https://placehold.co/600x400?text=Exclusive'" alt="Exclusive Image">
                        </div>  
                    </div>
                    <div class="col-lg-6">
                        <h1 class="mt-md-5 mb-4">พื้นที่โฆษณา</h1>
                        <div class="badges text-center mb-2 mt-5">
                            <span class="badge bg-custom me-2" style="font-weight: 400;">ขาย/เช่า</span>
                            <span class="badge bg-custom me-2" style="font-weight: 400;">บ้าน/ที่ดิน/คอนโด</span>
                        </div>
                        <h4 class="py-2 px-2">พื้นที่โฆษณาสำหรับประกาศ Exclusive</h4>
                        <h4 class="mt-5 mb-2"><i class="fa fa-check text-primary me-3"></i>เพิ่มการมองเห็น</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- Property List Start -->
        <div class="container-xxl py-5">
            <div class="container">
                <div class="row g-0 gx-5 align-items-end">
                    <div class="col-lg-6">
                        <div class="text-start mx-auto mb-5">
                            <h1 class="mb-3">ประกาศแนะนำ</h1>
                        </div>
                    </div>
                </div>
                
                
                <div class="tab-content mb-5">
                    <div id="tab-1" class="tab-pane p-0 active">
                        <div class="row g-4">
                            <div 
                                v-for="(property, index) in filteredItems" 
                                :key="index" 
                                class="card-custom-mk1 col-xl-3 col-lg-4 col-md-4 col-sm-6" 
                            >
                            <router-link :to="{ name: 'PropertyDetail', params: { id: property._id } }" class="text-decoration-none text-dark">
                                <div class="property-item rounded shadow overflow-hidden" style="border: 1px solid #2d1f65;">
                                    <div class="content-wrapper">
                                        
                                            <div class="position-relative overflow-hidden">
                                                <img 
                                                    class="img-fluid fixed-size-img responsive.img" 
                                                    :src="property.images?.[0]?.url || 'https://placehold.co/600x400?text=Premium'"
                                                    alt=""
                                                >
                                                <div 
                                                    v-if="property.SelectedCard === 'premium'" 
                                                    class="text-white position-absolute start-0 top-0 py-1 px-3" 
                                                    style="background: #310bd8;"
                                                >
                                                    PREMIUM
                                                </div>
                                            </div>
                                            <div class="px-3 py-2 pb-0">
                                                <div class="badges text-center text-md-start mb-2">
                                                    <span class="badge bg-custom me-2" style="font-weight: 400;">{{ property.PropertyType }}</span>
                                                    <span class="badge bg-custom me-2" style="font-weight: 400;">{{ property.Category }}</span>
                                                </div>
                                                <h6 class="d-block text-custom mb-2">{{ shortenTitle(property.Title) || 'ไม่มีชื่อ' }}</h6>
                                                <p>
                                                    <i class="fa fa-map-marker-alt custom-icon-color me-2"></i>
                                                    {{ property.Location?.Province || 'จังหวัด' }} {{ property.Location?.Amphur || 'อำเภอ' }} {{ property.Location?.Tambon || 'ตำบล' }}
                                                </p>
                                                <h4 class="text-primary mb-3">฿ {{ property.Price.toLocaleString() || '0' }} บาท</h4>
                                            </div>
                                        
                                    </div>
                                    <!-- ส่วนของ <small> ที่ต้องการให้อยู่ด้านล่าง -->
                                    <div v-if="property.Category === 'ที่ดิน'" class="d-flex border-top">
                                        <small class="flex-fill text-center border-end py-2" style="color: #310bd8;">
                                            <i class="fa fa-solid fa-house-chimney text-custom me-1"></i>
                                            {{ property.Category || 'ประเภท' }}
                                        </small>
                                        <small class="flex-fill text-center py-2" style="color: #310bd8;">
                                            <i class="fa fa-ruler-combined text-custom me-1"></i>
                                            {{ property.Scale.ScaleRai || '0' }}-{{ property.Scale.ScaleNgan || '0' }}-{{ property.Scale.ScaleWah || '0' }} ไร่
                                        </small>
                                    </div>
                                    <div v-else class="d-flex border-top">
                                        <small class="flex-fill text-center border-end py-2" style="color: #310bd8;">
                                            <i class="fa fa-solid fa-house-chimney text-custom me-1"></i>
                                            {{ property.Category || 'ประเภท' }}
                                        </small>
                                        <small class="flex-fill text-center border-end py-2" style="color: #310bd8;">
                                            <i class="fa fa-bed text-custom me-1"></i>
                                            {{ property.houseDetails.bedrooms || '0' }} นอน
                                        </small>
                                        <small class="flex-fill text-center py-2" style="color: #310bd8;">
                                            <i class="fa fa-bath text-custom me-1"></i>
                                            {{ property.houseDetails.bathrooms || '0' }} น้ำ
                                        </small>
                                    </div>
                                </div>
                            </router-link>
                            
                            </div>
                            <div v-if="filteredItems.length === 0" class="row g-4">
                                <!-- Mockup Card เปล่า -->
                                <div class="card-custom-mk1 col-xl-3 col-lg-4 col-md-4 col-sm-6">
                                    <div class="property-item rounded shadow overflow-hidden" style="border: 1px solid #2d1f65;">
                                    <div class="content-wrapper">
                                        <div class="position-relative overflow-hidden">
                                        <img 
                                            class="img-fluid fixed-size-img responsive.img" 
                                            src="https://placehold.co/600x400?text=PropertyCard" 
                                            alt="Mockup"
                                        >
                                        </div>
                                        <div class="px-3 py-2 pb-0">
                                        <div class="badges text-center text-md-start mb-2">
                                            <span class="badge bg-custom me-2" style="font-weight: 400;">ขาย/เช่า</span>
                                            <span class="badge bg-custom me-2" style="font-weight: 400;">หมวดหมู่</span>
                                        </div>
                                        <h6 class="d-block text-custom mb-2">ชื่อประกาศ</h6>
                                        <p>
                                            <i class="fa fa-map-marker-alt custom-icon-color me-2"></i>
                                            จังหวัด อำเภอ ตำบล
                                        </p>
                                        <h4 class="text-primary mb-3">฿ XXXXXXX บาท</h4>
                                        </div>
                                    </div>
                                    <div class="d-flex border-top">
                                        <small class="flex-fill text-center border-end py-2" style="color: #310bd8;">
                                        <i class="fa fa-solid fa-house-chimney text-custom me-1"></i>
                                        ประเภท
                                        </small>
                                        <small class="flex-fill text-center py-2" style="color: #310bd8;">
                                        <i class="fa fa-ruler-combined text-custom me-1"></i>
                                        ขนาด
                                        </small>
                                    </div>
                                    </div>
                                </div>
                                </div>
                        </div>
                    </div>
                </div>
                
                <div class="row g-0 gx-5 align-items-end">
                    <div class="col-lg-12">
                        <div class="text-start mx-auto mb-5">
                            <h1 class="mb-3 mt-5">ประกาศ Hot ล่าสุด</h1>
                        </div>
                    </div>
                    <div>
                        <EmblaCarouselCondo selectedCard="hot"/>
                    </div>
                </div>
                <div class="row g-0 gx-5 align-items-end mt-5">
                    <div class="col-lg-6">
                        <div class="text-start mx-auto mb-5">
                            <h1 class="mb-3 mt-5">ประกาศคอนโดล่าสุด</h1>
                            <!-- <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit diam justo sed rebum.</p> -->
                        </div>
                    </div>
                    <div class="col-lg-6 text-start text-lg-end">
                        <ul class="nav nav-pills d-inline-flex justify-content-end mb-5">
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabCondo === 'ทั้งหมด' }"
                                @click="setActiveTabCondo('ทั้งหมด')"
                                >
                                    ทั้งหมด
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabCondo === 'ขาย' }"
                                @click="setActiveTabCondo('ขาย')"
                                >
                                    ขาย
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabCondo === 'เช่า' }"
                                @click="setActiveTabCondo('เช่า')"
                                >
                                    เช่า
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <!-- คอมโพเนนต์ EmblaCarouselCondo -->
                        <div class="tab-content mt-3">
                            <div v-show="activeTabCondo === 'ทั้งหมด'">
                                <EmblaCarouselCondo category="คอนโด"/>
                            </div>
                            <div v-show="activeTabCondo === 'ขาย'">
                                <EmblaCarouselCondo propertyType="ขาย" category="คอนโด"/>
                            </div>
                            <div v-show="activeTabCondo === 'เช่า'">
                                <EmblaCarouselCondo propertyType="เช่า" category="คอนโด"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5 text-center">
                        <router-link to="/propertyList" class="text-decoration-none text-dark">
                            <a class="btn custom-outline-btn py-3 px-5" href="">ดูทั้งหมด</a>
                        </router-link>
                    </div>
                </div>

                <div class="row g-0 gx-5 align-items-end mt-5">
                    <div class="col-lg-6">
                        <div class="text-start mx-auto mb-5">
                            <h1 class="mb-3">ประกาศขายล่าสุด</h1>
                            <!-- <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit diam justo sed rebum.</p> -->
                        </div>
                    </div>
                    <div class="col-lg-6 text-start text-lg-end">
                        <ul class="nav nav-pills d-inline-flex justify-content-end mb-5">
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabSell === 'ทั้งหมด' }"
                                @click="setActiveTabSell('ทั้งหมด')"
                                >
                                    ทั้งหมด
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabSell === 'คอนโด' }"
                                @click="setActiveTabSell('คอนโด')"
                                >
                                    คอนโด
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabSell === 'บ้าน' }"
                                @click="setActiveTabSell('บ้าน')"
                                >
                                    บ้าน
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabSell === 'ที่ดิน' }"
                                @click="setActiveTabSell('ที่ดิน')"
                                >
                                    ที่ดิน
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary other-sm-device" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabSell === 'อื่นๆ' }"
                                @click="setActiveTabSell('อื่นๆ')"
                                >
                                    อื่น ๆ
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <!-- คอมโพเนนต์ EmblaCarouselCondo -->
                        <div class="tab-content mt-3">
                            <div v-show="activeTabSell === 'ทั้งหมด'">
                                <EmblaCarouselCondo propertyType="ขาย"/>
                            </div>
                            <div v-show="activeTabSell === 'คอนโด'">
                                <EmblaCarouselCondo propertyType="ขาย" category="คอนโด"/>
                            </div>
                            <div v-show="activeTabSell === 'บ้าน'">
                                <EmblaCarouselCondo propertyType="ขาย" category="บ้าน"/>
                            </div>
                            <div v-show="activeTabSell === 'ที่ดิน'">
                                <EmblaCarouselCondo propertyType="ขาย" category="ที่ดิน"/>
                            </div>
                            <div v-show="activeTabSell === 'อื่นๆ'">
                                <EmblaCarouselCondo propertyType="ขาย" category="อื่นๆ"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-5 text-center">
                        <router-link to="/propertyList" class="text-decoration-none text-dark">
                            <a class="btn custom-outline-btn py-3 px-5" href="">ดูทั้งหมด</a>
                        </router-link>
                    </div>
                </div>

                <div class="row g-0 gx-5 align-items-end mt-5">
                    <div class="col-lg-6">
                        <div class="text-start mx-auto mb-5">
                            <h1 class="mb-3">ประกาศเช่าล่าสุด</h1>
                        </div>
                    </div>
                    <div class="col-lg-6 text-start text-lg-end">
                        <ul class="nav nav-pills d-inline-flex justify-content-end mb-5">
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabRent === 'ทั้งหมด' }"
                                @click="setActiveTabRent('ทั้งหมด')"
                                >
                                    ทั้งหมด
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabRent === 'คอนโด' }"
                                @click="setActiveTabRent('คอนโด')"
                                >
                                    คอนโด
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabRent === 'บ้าน' }"
                                @click="setActiveTabRent('บ้าน')"
                                >
                                    บ้าน
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabRent === 'ที่ดิน' }"
                                @click="setActiveTabRent('ที่ดิน')"
                                >
                                    ที่ดิน
                                </a>
                            </li>
                            <li class="nav-item me-2">
                                <a 
                                class="btn custom-outline-btn-secondary other-sm-device" 
                                data-bs-toggle="pill" 
                                :class="{ active: activeTabRent === 'อื่นๆ' }"
                                @click="setActiveTabRent('อื่นๆ')"
                                >
                                    อื่น ๆ
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        
                        <!-- คอมโพเนนต์ EmblaCarouselCondo -->
                        <div class="tab-content mt-3">
                            <div v-show="activeTabRent === 'ทั้งหมด'">
                                <EmblaCarouselCondo propertyType="เช่า"/>
                            </div>
                            <div v-show="activeTabRent === 'คอนโด'">
                                <EmblaCarouselCondo propertyType="เช่า" category="คอนโด"/>
                            </div>
                            <div v-show="activeTabRent === 'บ้าน'">
                                <EmblaCarouselCondo propertyType="เช่า" category="บ้าน"/>
                            </div>
                            <div v-show="activeTabRent === 'ที่ดิน'">
                                <EmblaCarouselCondo propertyType="เช่า" category="ที่ดิน"/>
                            </div>
                            <div v-show="activeTabRent === 'อื่นๆ'">
                                <EmblaCarouselCondo propertyType="เช่า" category="อื่นๆ"/>
                            </div>
                        </div>
                        <div class="col-12 mt-5 text-center mb-5">
                            <router-link to="/propertyList" class="text-decoration-none text-dark">
                                <a class="btn custom-outline-btn py-3 px-5" href="">ดูทั้งหมด</a>
                            </router-link>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <!-- Property List End -->
    </main>
  </template>
  
  <script>
  import EmblaCarouselCondo from '../views/EmblaCarouselCondo.vue'
  import EmblaCarouselBanner from './EmblaCarouselBanner.vue';
  import axios from '@/axios.js';
  import SearchBar from '../components/SearchBar.vue';
  import CategoryList from './CategoryList.vue'
  import { WOW } from 'wowjs';
  import 'animate.css';

  export default {
    name: 'HomePage',
    components: {
        SearchBar,
        CategoryList,
        //CarouselWithCards,
        EmblaCarouselCondo,
        EmblaCarouselBanner,
        // EmblaCarousel2,
    },
    computed: {
        formattedDetail1() {
            return this.shortenDetailEx(this.itemEx[0]?.Detail ? this.itemEx[0].Detail.replace(/\n/g, '<br>') : '');
        },
        formattedDetail2() {
            return this.shortenDetailEx(this.itemEx[1]?.Detail ? this.itemEx[1].Detail.replace(/\n/g, '<br>') : '');
        }
    },
    methods: {
        setActiveTabRent(tab) {
            this.activeTabRent = tab;
        },
        setActiveTabSell(tab) {
            this.activeTabSell = tab;
        },
        setActiveTabCondo(tab) {
            this.activeTabCondo = tab;
        },
        setActiveTabHot(tab) {
            this.activeTabHot = tab;
        },
        async fetchProperties() {
            // let params = this.filterFind;
            // console.log('Parameters being sent:', this.filterFind);
            try {
                const response = await axios.get('/api/property/all', { 
                    params: { 
                        SelectedCard: 'premium',
                        limit: 8
                    }
                });
                this.item = response.data.map(property => ({
                    ...property,
                    images: property.images || [],
                    Price: property.Price.toLocaleString()
                }));
                console.log('response data :',response);
                this.filteredItems = this.item;
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        },
        async fetchPropertiesEx() {
            // let params = this.filterFind;
            // console.log('Parameters being sent:', this.filterFind);
            try {
                const response = await axios.get('/api/property/all', { 
                    params: { 
                        SelectedCard: 'exclusive',
                        limit: 2
                    }
                });
                this.itemEx = response.data.map(property => ({
                    ...property,
                    images: property.images || [],
                    Price: property.Price.toLocaleString()
                }));
            } catch (error) {
                console.error('Error fetching properties:', error);
            }
        },
        shortenTitle(title) {
            return typeof title === 'string' && title.length > 90 ? title.substring(0, 90) + '...' : title || 'พื้นที่ประกาศ';
        },
        shortenDetailEx(title) {
            return typeof title === 'string' && title.length > 230 ? title.substring(0, 230) + '...' : title || 'พื้นที่ประกาศ';
        },
    },
    data() {
        return {
            items: [
                { imageUrl: "/img/KaiTdinPro.png", alt: "Promotion" },
                { imageUrl: '/img/KaiTdinAd.png', alt: "Advertise Section" },
                // Add more items as needed
            ],
            defaultHeight: '300px', // กำหนดค่าความสูงเริ่มต้นของรูปภาพ
            carouselInstance: null, // ประกาศ carouselInstance ใน data
            activeTabRent: 'ทั้งหมด',
            activeTabSell: 'ทั้งหมด',
            activeTabCondo: 'ทั้งหมด',
            activeTabHot: 'ทั้งหมด',
            filteredItems: [],
            item: [],
            itemEx: [],
        };
    },
    mounted() {
        // Initialize WOW.js
        new WOW({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true
        }).init();
    },
    beforeUnmount() {
        // Clean up carousel instance when component is destroyed
        if (this.carouselInstance) {
            this.carouselInstance.dispose();
        }
    },
    created() {
        this.fetchProperties();
        this.fetchPropertiesEx()
    }
    
  };
  </script>
  
  <style scoped>
  
@import 'animate.css';

    .card {
        border: 1px solid #dee2e6;
        border-radius: 0.375rem;
    }
    .property-item {
        display: flex;
        flex-direction: column;
        height: 500px; /* กำหนดความสูงคงที่ตามที่คุณต้องการ */
    }

    .content-wrapper {
        flex: 1;
    }

    .property-item > .d-flex {
        margin-top: auto;
    }
    .card-custom-mk1 {
        transition: transform 0.3s ease-in-out;
    }
    .card-custom-mk1:hover {
        transform: scale(1.025); /* ปรับขนาดเมื่อ hover */
    }
    .fixed-size-img {
        width: 100%; /* ปรับความกว้างให้เต็มการ์ด */
        height: 250px; /* กำหนดความสูงที่ต้องการ */
        object-fit: cover; /* ทำให้ภาพครอบคลุมพื้นที่ */
    }
    /*.carousel-indicators [data-bs-target] {
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-color: grey; // สีพื้นหลัง 
        border-radius: 50%; // ทำให้วงกลม 
        width: 50px; // กำหนดความกว้าง 
        height: 50px; // กำหนดความสูง 
        display: flex;
        align-items: center;
        justify-content: center;
    }*/
    .fixed-size-img {
        width: 500px; /* กำหนดความกว้างที่ต้องการ */
        height: 250px; /* กำหนดความสูงที่ต้องการ */
        object-fit: cover; /* ปรับขนาดภาพให้ครอบคลุมพื้นที่ */
    }
    .ctn_resetpd{
        padding-left: 0;
        padding-right: 0;
    }
    #pagination1{
        margin-top: 10px;
        justify-content: center;
    }
    .responsive-img {
        width: 100%;
        height: 300px;
        object-fit: cover; /* ทำให้ภาพถูกครอบคลุมพื้นที่โดยไม่บิดเบี้ยว */
    }
    .about-container {
        position: relative; /* ทำให้กล่องหลักมีตำแหน่ง relative */
    }
    .exclusive-badge-right {
        position: absolute;
        top: 0px;
        right: 0px;
        background-color: #efbf04;
        color: white;
        padding: 15px 50px;
        font-weight: bold;
        border-radius: 5px;
        z-index: 999;
    }
    .exclusive-badge-left {
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: #efbf04;
        color: white;
        padding: 15px 50px;
        font-weight: bold;
        border-radius: 5px;
        z-index: 999;
    }
    .other-sm-device {
        margin-top: 0;
    }
    @media (max-width: 912px) {
        .responsive-img {
            height: 290px;
        }
    }
    @media (max-width: 767px) {
        .responsive-img {
            height: 250px;
        }
    }

    @media (max-width: 576px) {
        .responsive-img {
            height: 200px;
        }
    }
    @media (max-width: 414px) {
        .responsive-img {
            height: 175px;
        }
    }
    @media (max-width: 407px) {
        .other-sm-device {
            margin-top: 0.5rem;
        }
    }
    
    .bg-fade {
        margin: 0;
        background-image: linear-gradient(to bottom, #2d1f65, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00, #ffffff00);
        background-repeat: no-repeat; /* ห้ามทำซ้ำ */
    }
  </style>
  