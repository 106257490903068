<template>
  <div class="embla">
    <div class="embla__viewport" ref="emblaViewport">
      <div class="embla__container">
        <div class="embla__slide" v-for="(item, index) in displayItems" :key="index">

          <div class="embla_slide_inner">
            <!-- <router-link to="/propertyDetail" class="text-decoration-none text-dark"> -->
            <div class="card"  @click="navigateToDetail(item._id)">
              
              <img :src="item.images?.[0]?.url || 'https://placehold.co/600x400?text=Property+\n+Card'" alt="" class="responsive-image rounded-top card-img-top zoom"/>
              <div v-if="this.selectedCard" class="rounded text-white position-absolute start-0 top-0 py-1 px-3" style="background: #c92a0d;">HOT</div>
              <div v-if="item.SelectedCard === 'exclusive'" class="rounded text-white position-absolute start-0 top-0 py-1 px-3" style="background: #efbf04;">
                Exclusive
              </div>
              <div v-if="item.SelectedCard === 'premium'" class="rounded text-white position-absolute start-0 top-0 py-1 px-3" style="background: #310bd8;">
                PREMIUM
              </div>
              <div v-if="item.SelectedCard === 'hot'" class="rounded text-white position-absolute start-0 top-0 py-1 px-3" style="background: #c92a0d;">
                HOT
              </div>
              <div class="card-body pb-0">
                <div class="badges text-center text-md-start mb-2">
                    <span class="badge bg-custom me-1" style="font-weight: 400;">{{ item.PropertyType }}</span>
                    <span class="badge bg-custom me-1" style="font-weight: 400;">{{ item.Category }}</span>
                    <span v-if="item.Category === 'ที่ดิน'" class="badge bg-custom me-1" style="font-weight: 400;">
                        {{ item.Scale?.ScaleRai || '0' }}-{{ item.Scale?.ScaleNgan || '0' }}-{{ item.Scale?.ScaleWah || '0' }} ไร่
                    </span>
                    <span v-if="item.Category !== 'ที่ดิน'" class="badge bg-custom me-1" style="font-weight: 400;">
                        {{ item.houseDetails?.bedrooms || '0' }} นอน
                    </span>
                    <span v-if="item.Category !== 'ที่ดิน'" class="badge bg-custom me-1" style="font-weight: 400;">
                        {{ item.houseDetails?.bathrooms || '0' }} น้ำ
                    </span>
                </div>
                <h6 class="mb-3 text-custom">{{ shortenTitle(item.Title) || 'พื้นที่ประกาศ' }}</h6>
                <p class="mb-0" style="font-size: small;">
                  <i class="fa fa-map-marker-alt me-1 custom-icon-color"></i>
                  {{ item.Location?.Province || 'จังหวัด' }} {{ item.Location?.Amphur || 'อำเภอ' }} {{ item.Location?.Tambon || 'อำเภอ' }}
                </p>
                
              </div>
              <div class="card-footer" style="border-top: none;">
                <h5 v-if="item.PropertyType === 'ขาย'" class="text-custom">฿ {{ item.Price || 'XXXXXXX' }} บาท</h5>
                <h5 v-if="item.PropertyType === 'เช่า'" class="text-custom">฿ {{ item.Price || 'XXXXXXX' }}/เดือน</h5>
              </div>
            </div>
            
          <!-- </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <button @click="scrollPrev" class="embla__prev">
      <i class="fas fa-chevron-left"></i>
    </button>
    <button @click="scrollNext" class="embla__next">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from '@/axios.js';
import EmblaCarousel from 'embla-carousel';

export default {
  props: {
    category: {
      type: String,
      required: false,
      default: '' // ใช้ default เป็นค่าว่างหากไม่มีการส่งค่า category มา
    },
    propertyType: {
      type: String,
      required: false,
      default: '' // ใช้ default เป็นค่าว่างหากไม่มีการส่งค่า category มา
    },
    selectedCard: {
      type: String,
      required: false,
      default: '' // ใช้ default เป็นค่าว่างหากไม่มีการส่งค่า category มา
    },
    customFilters: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  setup() {
    const emblaViewport = ref(null);
    const emblaApi = ref(null);

    onMounted(() => {
      emblaApi.value = EmblaCarousel(emblaViewport.value, { loop: false });
    });

    const scrollPrev = () => {
      emblaApi.value.scrollPrev();
    };

    const scrollNext = () => {
      emblaApi.value.scrollNext();
    };

    return {
      emblaViewport,
      scrollPrev,
      scrollNext,
    };
  },
  data() {
    return {
      filteredItems: [],
      items: [],
    }
  },
  created() {
    this.fetchProperties();
  },
  computed: {
    displayItems() {
      const minimumItems = 5;
      const currentItems = this.items.length;
      return currentItems < minimumItems
        ? [...this.filteredItems, ...Array(minimumItems - currentItems).fill({})]
        : this.filteredItems;
    },
  },
  methods: {
    navigateToDetail(itemId) {
      if(!itemId) return;
      this.$router.push({ name: 'PropertyDetail', params: { id: itemId } });
    },
    async fetchProperties() {
      let params = { ...this.customFilters };

      if (this.category) {
        params.Category = this.category;
      }
      if (this.propertyType) {
        params.PropertyType = this.propertyType;
      }
      if (this.selectedCard) {
        params.SelectedCard = this.selectedCard;
      }
      params.limit = 10;
      try {
          const response = await axios.get('/api/property/all', { params });
          this.items = response.data.map(property => ({
              ...property,
              images: property.images || [],
              Price: property.Price.toLocaleString()
          }));
          this.filteredItems = this.items;
      } catch (error) {
          console.error('Error fetching properties:', error);
      }
    },
    shortenTitle(title) {
      return typeof title === 'string' && title.length > 60 ? title.substring(0, 60) + '...' : title || 'พื้นที่ประกาศ';
    },
  },
};
</script>

<style scoped>
.custom-icon-color {
  color: #6381d1;
}

.card {
  height: 410px; /* กำหนดความสูงของการ์ดให้เท่ากัน */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* ทำให้เนื้อหาในการ์ดถูกจัดเรียงระหว่างส่วนบนและล่าง */
  overflow: hidden;
  border: 1px solid #2d1f65;
  transition: transform 0.3s ease-in-out;
}
.card:hover {
    transform: scale(1.025); /* ปรับขนาดเมื่อ hover */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือ */
    .card-footer {
      background-color: #e3def8;
      transition: background-color 0.3s ease-in-out;
    }
}
.zoom {
  max-width: 100%; /* กำหนดขนาดสูงสุดของภาพ */
  height: auto; /* รักษาอัตราส่วนของภาพ */
  transition: .5s;
  object-fit: contain;
}
.card:hover .zoom{
  transform: scale(1.05);
}

.embla_slide_inner {
  display: grid;
  grid-template-rows: auto 1fr; /* แบ่งพื้นที่เป็น 2 ส่วน ส่วนบนสูงเท่ากับเนื้อหา ส่วนล่างสูงเท่าที่เหลือ */
}

.card-body {
  overflow-y: auto; /* สร้าง scrollbar เมื่อเนื้อหาเกิน */
}
.embla {
  position: relative;
  overflow: hidden;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__container {
  display: flex;
}

.embla__slide {
  position: relative;
  min-width: 22%;
  margin-right: 10px;
  padding: 5px;
}

.responsive-image {
  width: 100%;
  height: 200px; /* กำหนดความสูงของรูปภาพให้คงที่ */
  object-fit: cover; /* ทำให้ภาพถูกครอบคลุมพื้นที่โดยไม่บิดเบี้ยว */
}

.embla__prev:hover,
.embla__next:hover {
  background: #1a123b; /* เปลี่ยนสีพื้นหลังเมื่อ hover */
}

.embla__prev,
.embla__next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #2d1f65; /* พื้นหลังสีม่วงเข้ม */
  color: #fff; /* สีของไอคอน */
  border: none;
  border-radius: 50%; /* ทำให้เป็นวงกลม */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10; /* เพิ่ม z-index ให้ปุ่มอยู่เหนือองค์ประกอบอื่น ๆ */
}

.embla__prev {
  left: 10px; /* ปรับระยะห่างจากขอบซ้าย */
}

.embla__next {
  right: 10px; /* ปรับระยะห่างจากขอบขวา */
}

.embla__prev:hover,
.embla__next:hover {
  background: #1a123b; /* เปลี่ยนสีพื้นหลังเมื่อ hover */
}

.rounded-top {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
@media (max-width: 768px) {
  .embla__slide {
    min-width: 35%; /* แสดง 2 การ์ดบนหน้าจอเล็ก */
  }
}

@media (max-width: 480px) {
  .embla__slide {
    min-width: 60%; /* แสดง 1 การ์ดบนหน้าจอเล็กมาก */
  }
}
@media (max-width: 1024px) {
  .embla__slide {
    min-width: 35%; /* แสดง 1 การ์ดบนหน้าจอเล็กมาก */
  }
}
/* Media Queries สำหรับหน้าจอขนาดเล็ก */
@media (max-width: 575px) {
  .embla__slide {
    min-width: 80%; /* แสดงทีละ 1 การ์ดเมื่อหน้าจอมีขนาดเล็ก */
    padding: 5px;
  }

  .card-body {
    padding: 10px;
    flex: 1; /* ให้ card-body ยืดได้เพื่อให้การ์ดมีขนาดเต็ม */
    overflow-y: auto;
  }

  .card-title {
    font-size: 14px;
  }

  .card-price {
    font-size: 12px;
  }
}
</style>
